import React, { useContext, useEffect } from "react";
import { WatchQueryFetchPolicy } from "@apollo/client";

import MissingProperty from "../MissingProperty";
import { track } from "../../../utils/tracking";
import { useGetActivitiesQuery } from "../../../generated/graphql";
import Icon, { ICON_COLORS, Icons } from "../../Common/Icons";
import CommentForm from "./CommentForm";
import Feed from "../../Feed";

import "./__styles__/Activity.scss";
import { EmptyState } from "../../Common/EmptyState";
import { AddressPanelTabProps } from "..";
import { AddressPanelContext } from "../AddressPanelContext";

export default ({
  address,
  latitude,
  longitude,
  fetchPolicy = "cache-and-network",
}: AddressPanelTabProps & { fetchPolicy?: WatchQueryFetchPolicy }) => {
  const { property, loading: loadingPropertyId } =
    useContext(AddressPanelContext);
  const propertyId = property?.id;
  if (!propertyId && !loadingPropertyId)
    return (
      <MissingProperty
        title="No activity tracking available"
        address={address}
        latitude={latitude}
        longitude={longitude}
      />
    );

  const { data, loading, error, refetch } = useGetActivitiesQuery({
    variables: { propertyId: propertyId! },
    fetchPolicy,
    skip: loadingPropertyId,
  });

  useEffect(() => {
    track("Opened activity", { address });
  }, [address]);

  if (!data || loading || loadingPropertyId)
    return (
      <div styleName="loading">
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </div>
    );

  if (error) return <em>Problem loading activity.</em>;

  const activities = data.activities.map(activity => {
    return {
      ...activity,
      property: {
        id: propertyId!,
        latitude,
        longitude,
        streetAddress: address,
        fullAddress: address,
      },
    };
  });

  return (
    <>
      <section>
        <CommentForm propertyId={propertyId!} onUpdate={refetch} />
      </section>
      {!activities.length && (
        <div styleName="empty">
          <EmptyState
            icon="add-file"
            title="No activity for this property"
            body="Add a comment to start tracking activity."
          />
        </div>
      )}

      <Feed
        activities={activities}
        onUpdate={() => refetch()}
        linkToProperty={false}
        truncateComments={false}
        allowEdits={true}
        feedSource={"Property"}
      />
    </>
  );
};
