import React from "react";
import Banner from "../Common/Banner";
import { Header, Section } from "../Common/__styles__/FullPageFormLayout";
import { BANNER_TYPES } from "../Common/__styles__/Banner";

export default ({
  header,
  warnings,
  children,
}: {
  header?: Maybe<string>;
  warnings: Array<string>;
  children?: React.ReactNode;
}) => (
  <>
    {header && (
      <Header>
        <h1>{header}</h1>
      </Header>
    )}
    <Section data-testid="property-warnings">
      {warnings.map(text => {
        const warning = text !== "None";
        return (
          <Banner
            key={text}
            type={warning ? BANNER_TYPES.ATTENTION : BANNER_TYPES.INFO}
          >
            {text}
          </Banner>
        );
      })}
      {children}
    </Section>
  </>
);
