import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../../Common/__styles__/StripedTable";
import { DropdownMenu } from "../../../../Inputs";
import { ActionsProps } from "../../../../Inputs/DropdownMenu";

import { PROPERTY_ATTRIBUTE_SOURCE } from "common/constants";
import { RasterOptions } from "..";

import {
  CustomMap,
  GetPropertyInformationSectionQuery,
  useUpdateAccountPropertyAttributeDisplayOrderMutation,
  useUpdateOrCreatePropertyAttributeMutation,
} from "../../../../../generated/graphql";
import { useUpdateOrCreateAttributeModal } from "./updateOrCreateSectionAttributeModal";
import {
  DeleteAttributeModalProps,
  useDeleteAttributeModal,
} from "./deleteAttributeModal";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { FlexRow } from "../../../../Common/__styles__/Layout";
import Divider from "../../../../Common/Divider";
import { PropertyOverviewTooltip } from "../../../../AddressPanel/PropertyOverview/Common/PropertyOverviewTooltip";
import { Body } from "../../../../Common/Typography";
import { EmptyState } from "../../../../Common/EmptyState";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { RowDragHandleCell } from "../../../../Common/Tables/RowDragHandleCell";
import { colors } from "../../../../../stitches.config";

export type BasePropertyAttributeTableProps = Omit<
  NonNullable<
    GetPropertyInformationSectionQuery["propertyInformationSection"]
  >["accountPropertyAttributes"][number],
  "__typename"
>;

const DraggableRow = ({
  attribute,
  publicPortalEnabled,
  renderActionButton,
}: {
  attribute: BasePropertyAttributeTableProps;
  publicPortalEnabled: boolean;
  renderActionButton: any;
}) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: attribute.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    // Have to set as undefined so actions dropdown can have its z index set
    zIndex: isDragging ? 1 : undefined,
    position: "relative",
    backgroundColor: isDragging ? colors.bgUiContainer.toString() : undefined,
    borderRadius: "4px",
  };

  return (
    <TableRow style={style} ref={setNodeRef}>
      <FlexRow style={{ flexBasis: "50%", paddingLeft: "12px" }}>
        <RowDragHandleCell rowId={attribute.id} />
        <TableCell
          style={{
            margin: "auto 0",
            paddingLeft: "4px",
            overflowX: "hidden",
            whiteSpace: "normal",
            textAlign: "left",
            flexBasis: "auto",
          }}
        >
          {attribute.label}
          {publicPortalEnabled && (
            <PropertyOverviewTooltip
              isGuestOnly={false}
              tooltipMarkdown={attribute.tooltip}
            />
          )}
        </TableCell>
      </FlexRow>

      <FlexRow style={{ margin: "auto 0" }}>
        {publicPortalEnabled && (
          <TableCell style={{ margin: "auto 0" }}>
            {attribute.isPublic ? "Displayed" : "Hidden"}
          </TableCell>
        )}

        <TableCell>
          {renderActionButton({
            existingPropertyAttribute: attribute,
          })}
        </TableCell>
      </FlexRow>
    </TableRow>
  );
};

const SectionAttributesTable = ({
  currentData,
  sectionId,
  rasterOptions,
  customMapOptions,
  onUpdate,
}: {
  currentData: {
    accountPropertyAttributes?: Array<BasePropertyAttributeTableProps>;
    ruleDefinitions: DeleteAttributeModalProps["ruleDefinitions"];
  };
  sectionId: string;
  rasterOptions: RasterOptions;
  customMapOptions: Array<Pick<CustomMap, "id" | "type" | "name">>;
  onUpdate: () => void;
}) => {
  const { account } = React.useContext(AuthContext);
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const [updateOrCreatePropertyAttribute] =
    useUpdateOrCreatePropertyAttributeMutation();

  const [showEditAttributeModal] = useUpdateOrCreateAttributeModal({
    onSubmit: updateOrCreatePropertyAttribute,
    onUpdate,
    rasterOptions,
    customMapOptions,
    sectionId,
  });

  const [showDeleteAttributeModal] = useDeleteAttributeModal({
    onUpdate,
    ruleDefinitions: currentData.ruleDefinitions,
  });

  const [updateDisplayOrder, { loading: loadingReorder }] =
    useUpdateAccountPropertyAttributeDisplayOrderMutation({
      onCompleted: () => {
        addSuccessToast("Attributes reordered successfully");
        onUpdate();
      },
      onError: () => {
        addErrorToast("Failed to reorder attributes");
      },
    });

  const [data, setData] = useState<Array<BasePropertyAttributeTableProps>>(
    currentData.accountPropertyAttributes ?? []
  );

  useEffect(() => {
    setData(currentData.accountPropertyAttributes ?? []);
  }, [currentData.accountPropertyAttributes]);

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => data.map(attribute => attribute.id),
    [data]
  );

  const handleDragEnd = (event: DragEndEvent) => {
    // To avoid race conditions, we exit early if an update is already in progress
    if (loadingReorder) {
      return;
    }
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setData(data => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);
        const newArray = arrayMove(data, oldIndex, newIndex);
        void updateDisplayOrder({
          variables: {
            data: {
              sectionId,
              attributes: newArray.map((attribute, index) => ({
                id: attribute.id,
                displayOrder: index,
              })),
            },
          },
        });

        return newArray;
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const renderActionButton = ({
    existingPropertyAttribute,
  }: {
    existingPropertyAttribute: BasePropertyAttributeTableProps;
  }) => {
    const actions: Array<ActionsProps> = [
      {
        label: "Edit attribute",
        onClick: () => {
          showEditAttributeModal({
            existingPropertyAttribute,
            rasterOptions,
          });
        },
      },
    ];
    if (
      existingPropertyAttribute.source === PROPERTY_ATTRIBUTE_SOURCE.RASTER ||
      existingPropertyAttribute.source === PROPERTY_ATTRIBUTE_SOURCE.CUSTOM_MAP
    ) {
      actions.push({
        label: "Delete",
        variant: "red",
        onClick: () =>
          showDeleteAttributeModal({
            id: existingPropertyAttribute.id,
            label: existingPropertyAttribute.label,
          }),
      });
    }

    return <DropdownMenu actions={actions} isTableAction />;
  };

  if (!currentData.accountPropertyAttributes?.length) {
    return (
      <div style={{ marginTop: "72px" }}>
        <EmptyState
          icon="add-file"
          title="No attributes for this section"
          body="Add an attribute to get started."
        />
      </div>
    );
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell style={{ paddingBottom: "8px", paddingLeft: "44px" }}>
              <Body size="default" type="emphasis">
                Attribute name
              </Body>
            </TableCell>
            {account?.publicPortal.enabled && (
              // 50px right is to align with the isPublic column, offsetting for the action button
              <TableCell
                style={{
                  marginRight: "50px",
                  paddingBottom: "8px",
                }}
              >
                <Body size="default" type="emphasis">
                  Public website
                </Body>
              </TableCell>
            )}
          </TableRow>
        </TableHeader>
        <Divider />
        <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
          <TableBody>
            {data.map(attribute => (
              <DraggableRow
                key={attribute.id}
                attribute={attribute}
                publicPortalEnabled={account?.publicPortal.enabled ?? false}
                renderActionButton={renderActionButton}
              />
            ))}
          </TableBody>
        </SortableContext>
      </Table>
    </DndContext>
  );
};

export default SectionAttributesTable;
