import React, { useContext } from "react";

import { Container, TabContainer } from "./__styles__/ObjectInformationPanel";

import { useGetCustomMapWithGeometryQuery } from "../../generated/graphql";
import Header from "./Header";
import Overview from "./Overview";
import { arrayHasAtLeastOneItem } from "common/utils/arrays";
import { LayerContext } from "../Maps/layers";
import { Bounds } from "viewport-mercator-project";
import { EmptyState } from "../Common/EmptyState";
import { OBJECT_TYPE, SUPPORT_EMAIL } from "common/constants";
import { INFO_PANEL_TAB_NAME } from "common/routing";
import { ObjectInfoPanelTabProps } from "./types";
import TabGroup from "../Common/TabGroup";
import useNavigationTab from "../../hooks/useNavigationTabs";
import { intersection, map } from "lodash";
import Records from "./Records";
import { AuthContext } from "../Authorization/AuthContext";

const DEFAULT_OBJECT_TYPE = "Object";
const DEFAULT_OBJECT_NAME = "-";
export const LOADING_TEXT = "Loading...";

const TabBody = (
  props: ObjectInfoPanelTabProps & { tab: INFO_PANEL_TAB_NAME }
) => {
  const { tab, ...childProps } = props;

  return (
    <>
      {tab === INFO_PANEL_TAB_NAME.OVERVIEW && <Overview {...childProps} />}
      {tab === INFO_PANEL_TAB_NAME.RECORDS && <Records {...childProps} />}
    </>
  );
};

type ObjectInformationPanelProps = {
  customMapId: string;
  geometryId: string;
  onClose: () => void;
};

const ObjectInformationPanel: React.FC<ObjectInformationPanelProps> = ({
  customMapId,
  geometryId,
  onClose,
}) => {
  let tabs = [
    { value: INFO_PANEL_TAB_NAME.OVERVIEW, label: "Overview" },
    { value: INFO_PANEL_TAB_NAME.RECORDS, label: "Records" },
  ];

  const { account } = useContext(AuthContext);

  const [tab, setTab] = useNavigationTab({
    defaultTab: tabs[0]!.value,
    allowedTabs: map(tabs, "value"),
  });

  const { toggleLayer, setGeometryBounds, setPointInGeometry } =
    useContext(LayerContext);

  const { data, loading } = useGetCustomMapWithGeometryQuery({
    variables: { customMapId, geometryId },
    onCompleted: ({ customMap }) => {
      if (customMap) {
        toggleLayer({
          group: "customMaps",
          id: customMap.id,
          isVisible: true,
        });
        if (arrayHasAtLeastOneItem(customMap.geometries)) {
          const geometry = customMap.geometries[0];
          setGeometryBounds(geometry.bounds as Bounds);
          if (geometry.interiorPoint) {
            setPointInGeometry({
              longitude: geometry.interiorPoint[0]!,
              latitude: geometry.interiorPoint[1]!,
            });
          }
        }
      }
    },
  });

  const customMap = data?.customMap;
  const geometry = customMap?.geometries[0];
  const objectType = customMap?.name ?? DEFAULT_OBJECT_TYPE;
  const objectName = geometry?.label ?? DEFAULT_OBJECT_NAME;

  const submissionCategoriesForCustomMap = new Set(
    account?.submissionTypes
      .filter(
        type =>
          type.attachments.filter(
            attachment =>
              attachment.type === OBJECT_TYPE.CUSTOM_MAP_GEOMETRY &&
              intersection(attachment.tags, customMap?.tags ?? []).length > 0
          ).length > 0
      )
      .map(type => type.category)
  );

  // hide records tab if there are no compatible submission types
  if (submissionCategoriesForCustomMap.size === 0) {
    tabs = tabs.filter(tab => tab.value !== INFO_PANEL_TAB_NAME.RECORDS);
  }

  const emptyTitle = `This ${
    customMap ? customMap.name.concat(" object") : "map"
  } is unavailable`;
  return (
    <Container>
      <Header
        loading={loading}
        objectType={objectType}
        objectName={objectName}
        onClose={onClose}
      />
      {geometry ? (
        <>
          <TabContainer>
            <TabGroup currentTab={tab} setTab={setTab} tabs={tabs} />
          </TabContainer>
          <TabBody
            tab={tab}
            geometry={geometry}
            customMap={customMap}
            submissionCategories={submissionCategoriesForCustomMap}
          />
        </>
      ) : loading ? (
        <div>
          <em>{LOADING_TEXT}</em>
        </div>
      ) : (
        <EmptyState
          icon="missing-location"
          title={emptyTitle}
          body="Our features are limited for objects that may have been recently updated or removed. Please wait and try your search again or reach out if the issue continues."
          primaryButtonProps={{
            label: "Contact us",
            onClick: () => window.open(`mailto:${SUPPORT_EMAIL}`),
          }}
        />
      )}
    </Container>
  );
};

export default React.memo(ObjectInformationPanel);
