import React, { ReactNode } from "react";
import {
  defaultSupportedMobileVersionsContext,
  SupportedMobileVersionsContextInstance,
} from "./contexts/supportedMobileVersionsContext";

const SupportedMobileVersionsContainer = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  // TODO(DASH-1420): Implement a GraphQL query to fetch the last supported mobile version for each field type.
  return (
    <SupportedMobileVersionsContextInstance.Provider
      value={defaultSupportedMobileVersionsContext}
    >
      {children}
    </SupportedMobileVersionsContextInstance.Provider>
  );
};

SupportedMobileVersionsContainer.displayName =
  "SupportedMobileVersionsContainer";

export default SupportedMobileVersionsContainer;
