import React, { useContext } from "react";
import { useModal } from "react-modal-hook";
import { useLocation } from "react-router";
import { RESOURCE_NAME } from "common/authorization";
import { INFO_PANEL_TAB_NAME, buildLink } from "common/routing";
import {
  Property,
  Submission,
  SubmissionStatus,
  SubmissionType,
} from "../../generated/graphql";
import DeleteConfirmation from "../AddressPanel/Submissions/DeleteConfirmation";
import { AuthContext } from "../Authorization/AuthContext";
import Modal from "../Common/Modal";
import { Actions } from "../Common/ActionCell";
import {
  ActionsProps,
  shouldShowViewOnMapAction,
} from "../Inputs/DropdownMenu";
import { track } from "../../utils/tracking";
import { SUBMISSION_CATEGORY, SUBMISSION_TYPE_MODULE } from "common/constants";
import { SUBMISSION_CATEGORY_TO_TAB } from "./utils";

export type SubmissionActionButtonProps = {
  isEditView?: boolean;
  isSummaryView?: boolean;
  submission: Pick<Submission, "id" | "status"> & {
    property?: Maybe<
      Pick<Property, "id" | "fullAddress" | "longitude" | "latitude">
    >;
    submissionTypeVersion: {
      submissionType: Pick<SubmissionType, "category" | "name">;
    };
    hasSummary: boolean;
    relatedSubmissions: Array<
      Pick<Submission, "id"> & {
        submissionTypeVersion: {
          submissionType: Pick<SubmissionType, "modules" | "name">;
        };
      }
    >;
    category: SUBMISSION_CATEGORY;
  };
  onUpdate?: () => void;
  additionalActions?: Array<ActionsProps>;
};

const getSubmissionTypeName = ({
  modules,
  name,
}: Pick<SubmissionType, "name" | "modules">) => {
  if (modules.includes(SUBMISSION_TYPE_MODULE.SUBSTANTIAL_DAMAGE_ESTIMATE)) {
    return "SDE";
  } else if (
    modules.includes(
      SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
    )
  ) {
    return "SD";
  } else {
    return name;
  }
};

const ActionButton = ({
  isEditView = false,
  isSummaryView = false,
  submission,
  onUpdate,
  additionalActions = [],
}: SubmissionActionButtonProps) => {
  const { pathname, search, state } = useLocation<{
    prevLocation?: Maybe<string>;
  }>();

  const { authorized, admin, account } = useContext(AuthContext);
  const isDetailView = isSummaryView || isEditView;
  const deletePrevLocation = isDetailView
    ? state?.prevLocation ?? ""
    : undefined;
  const [showDeleteSubmissionModal, hideDeleteSubmissionModal] = useModal(
    () => (
      <Modal onRequestClose={hideDeleteSubmissionModal}>
        <DeleteConfirmation
          submission={submission}
          prevLocation={deletePrevLocation}
          closeModal={hideDeleteSubmissionModal}
          onUpdate={onUpdate}
        />
      </Modal>
    )
  );

  const prevLocation = `${pathname}${search}`;
  const recordTab = SUBMISSION_CATEGORY_TO_TAB[submission.category];
  const hasSummary = submission.hasSummary;

  const canDeleteSubmission =
    authorized({
      resource: RESOURCE_NAME.SUBMISSION,
      permission: "delete",
    }) && submission.status !== SubmissionStatus.PROCESSING;

  const actions: Array<ActionsProps> = [];

  if (!isDetailView) {
    actions.push({
      label: "View",
      href: buildLink(
        "viewSubmission",
        { submissionId: submission.id },
        {},
        { admin: !!admin, accountId: account?.id! }
      ),
      target: "_blank",
      track: () => {
        track("Submission viewed", {
          submissionId: submission.id,
          "Submission Category":
            submission.submissionTypeVersion.submissionType?.category,
          "Submission Type":
            submission.submissionTypeVersion.submissionType?.name,
        });
      },
    });
  }

  if (isSummaryView) {
    actions.push({
      label: "Edit",
      to: {
        pathname: buildLink("editSubmission", {
          submissionId: submission.id,
        }),
        state: { prevLocation },
      },
    });
  }

  if (isEditView && hasSummary) {
    actions.push({
      label: "View summary",
      href: buildLink(
        "submissionSummary",
        { submissionId: submission.id },
        {},
        { admin: !!admin, accountId: account?.id! }
      ),
      target: "_blank",
    });
  }

  const relatedSubmissions = submission.relatedSubmissions;
  actions.push(
    ...relatedSubmissions.map(relatedSubmission => {
      return {
        label: `View ${getSubmissionTypeName(
          relatedSubmission.submissionTypeVersion.submissionType
        )} record`,
        href: buildLink(
          "viewSubmission",
          {
            submissionId: relatedSubmission.id,
          },
          {},
          { admin: !!admin, accountId: account?.id! }
        ),
        target: "_blank",
      };
    })
  );

  const property = submission.property;

  if (shouldShowViewOnMapAction(property)) {
    actions.push({
      label: "View on map",
      href: buildLink(
        "map",
        { accountId: account?.id! },
        {
          address: property.fullAddress,
          propertyId: property.id,
          lat: property.latitude.toString(),
          lng: property.longitude.toString(),
          tab: INFO_PANEL_TAB_NAME.RECORDS,
          recordTab,
        },
        { admin: !!admin, accountId: account?.id! }
      ),
      target: "_blank",
    });
  }

  actions.push(...additionalActions);

  actions.push({
    label: "Delete",
    onClick: () => showDeleteSubmissionModal(),
    disabled: !canDeleteSubmission,
    variant: "red",
    track: () => {
      track("Submission deleted", {
        submissionId: submission.id,
        "Submission Category":
          submission.submissionTypeVersion.submissionType?.category,
        "Submission Type":
          submission.submissionTypeVersion.submissionType?.name,
      });
    },
  });

  return (
    <Actions
      actions={actions}
      buttonText={isDetailView ? "Actions" : undefined}
    />
  );
};

export default ActionButton;
