import React, { ComponentProps } from "react";

import { Empty } from "../../__styles__/Content";
import { EmptyState } from "../../../Common/EmptyState";
import RecentImportTasksTable from "../RecentImportTasksTable";
import { ApolloError } from "@apollo/client";

export type FirmImportsProps = {
  error?: Pick<ApolloError, "message">;
} & Pick<
  ComponentProps<typeof RecentImportTasksTable>,
  "recentImportTasks" | "loading" | "manualPaginationConfig"
>;

export default ({
  recentImportTasks,
  error,
  loading,
  manualPaginationConfig,
}: FirmImportsProps) => {
  if (error) return <div>Error loading recent imports.</div>;

  const hasRecentFirmImports = !!recentImportTasks.length;

  return (
    <>
      {!loading && (
        <>
          {hasRecentFirmImports ? (
            <RecentImportTasksTable
              recentImportTasks={recentImportTasks}
              loading={loading}
              manualPaginationConfig={manualPaginationConfig}
            />
          ) : (
            <Empty>
              <EmptyState
                icon="missing-location"
                title="No recent imports for this account"
                body="FIRM imports for this account will appear here."
              />
            </Empty>
          )}
        </>
      )}
    </>
  );
};
