import React, { useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useGetSubmissionWithSummariesQuery } from "../../generated/graphql";
import { MODULE_CONFIGURATIONS } from "./modules";
import { isNotNil } from "common/utils/tools";
import { buildLink } from "common/routing";
import FullPageFormLayout from "../Common/FullPageFormLayout";
// move these shared styles out of create submission style fyle
import ActionButton from "./ActionButton";
import { ButtonContainer } from "./__styles__/Submission";
import SubmissionHeader from "./SubmissionHeader";
import { getObjectDisplay, hasSummary } from "./utils";
import { flatMap, map } from "lodash";
import { AuthContext } from "../Authorization/AuthContext";

const SubmissionSummary = ({
  moduleConfigurations = MODULE_CONFIGURATIONS,
}: {
  moduleConfigurations?: typeof MODULE_CONFIGURATIONS;
}) => {
  const routeMatch = useRouteMatch<{ submissionId: string }>();
  const submissionId = routeMatch.params.submissionId;

  const history = useHistory();
  const location = useLocation<Maybe<{ prevLocation?: string }>>();
  const prevLocation = location.state?.prevLocation || "/map";
  const { account } = useContext(AuthContext);

  const { data, loading } = useGetSubmissionWithSummariesQuery({
    variables: { submissionId },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      // if the submission doesn't have any summary components, redirect to the edit page
      // as the user probably got sent here by mistake somehow
      if (!data.submission?.summaries.length) {
        history.replace({
          pathname: buildLink("editSubmission", { submissionId }),
          state: { prevLocation },
        });
      }
    },
  });

  if (!data?.submission || loading) return <div>Loading...</div>;

  const submission = data.submission;
  const submissionType = submission.submissionTypeVersion.submissionType;
  const modules = submissionType.modules;
  const property = submission.property;
  const submissionTypes = account?.submissionTypes ?? [];

  const summaryComponents = map(
    modules,
    module => moduleConfigurations[module].summaryComponent
  ).filter(isNotNil);

  const additionalActions = flatMap(modules, module =>
    moduleConfigurations[module].buildActions({
      submission,
      property,
      submissionTypes,
    })
  );

  const rightContainer = (
    <ButtonContainer>
      <ActionButton
        isSummaryView={true}
        additionalActions={additionalActions}
        submission={{
          ...submission,
          category: submissionType.category,
          hasSummary: hasSummary(submission),
        }}
      />
    </ButtonContainer>
  );

  return (
    <FullPageFormLayout
      subtitle={submissionType.name}
      prevLocation={prevLocation}
      rightContainer={rightContainer}
      width="wide"
      buttonLoading={loading}
    >
      <div>
        <SubmissionHeader
          submissionType={submissionType}
          objectDisplay={getObjectDisplay({ property })}
        />
        <div>
          {summaryComponents.map((Component, index) => (
            <Component key={index} submission={submission} />
          ))}
        </div>
      </div>
    </FullPageFormLayout>
  );
};

export default SubmissionSummary;
