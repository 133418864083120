import React, {
  ComponentProps,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GeolocateControl, MapRef, NavigationControl } from "react-map-gl";
import { useLocation } from "react-router";
import { Bounds } from "viewport-mercator-project";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { captureException } from "@sentry/browser";

import LayeredMap, { LayerConfigs, Point } from "./LayeredMap";
import useViewport, { Viewport } from "./utils/viewportHook";
import {
  MapsForAccountQuery,
  useMapsForAccountQuery,
} from "../../generated/graphql";
import { LayerContext, LayerContextProvider } from "./layers";
import { Icon } from "../Common/Icons/LucideIcons";
import LayersPanel from "./LayersPanel";
import { useIsTabletWidth } from "../Guest/utils";
import { useOutsideAlerter } from "../../utils/outsideAlerter";
import GeolocateBanner from "./GeolocateBanner";
import { onMapAvailable } from "./utils/mapUtils";
import { ParcelLayerConfig } from "./LayeredMap/parcelLayer";
import { MarkerLayerConfig } from "./LayeredMap/markerLayer";
import { PropertyLayerConfig } from "./LayeredMap/propertyLayer";
import { SearchResultProps } from "../Search";

import {
  LegendControl,
  MapTool,
  MapToolLabel,
  ActiveFloodLayerBadge,
  NavigationContainer,
  LegendControlContainer,
  ZoomToLocationBanner,
  ZoomToLocationButton,
} from "./__styles__/FullPageMap";
import { AuthContext } from "../Authorization/AuthContext";
import { isSmall } from "../../utils/size";
import MeasureToolLegendControl from "./MeasureTool/MeasureToolLegendControl";
import { SavedViewsLayerConfig } from "./LayeredMap/savedViewsLayer";
import { SavedViewBanner } from "./SavedViewBanner";
import { UserDeviceLocationLegendControl } from "./UserDeviceLocation/UserDeviceLocationLegendControl";
import { DeviceLocationsConfig } from "./UserDeviceLocation/useUserDeviceLocations";
import { CustomMapLayerConfig } from "./LayeredMap/customMapLayer";
import { ClickObjectMenuLayerConfig } from "./LayeredMap/clickObjectMenuLayer";

const useQueryParams = (
  account: NonNullable<MapsForAccountQuery["account"]>
) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const longitude = parseFloat(params.lng as string);
  const latitude = parseFloat(params.lat as string);
  let selectedFirmId = params.firmId as Maybe<string>;
  if (!account.firms.some(accountFirm => accountFirm.id === selectedFirmId)) {
    selectedFirmId = account.firms.find(f => f.isDefault)?.id || null;
  }

  return {
    latitude,
    longitude,
    selectedFirmId,
  };
};

const FullPageMap = React.forwardRef(
  (
    {
      account,
      markerConfig,
      parcelConfig,
      propertyConfig,
      savedViewsConfig,
      deviceLocationsConfig,
      customMapsConfig,
      clickObjectMenuConfig,
      onSearchResult,
      viewportDimensions,
      MapControls,
      children,
    }: {
      account: NonNullable<MapsForAccountQuery["account"]>;
      markerConfig: MarkerLayerConfig;
      parcelConfig: ParcelLayerConfig;
      propertyConfig: PropertyLayerConfig;
      savedViewsConfig: SavedViewsLayerConfig;
      deviceLocationsConfig?: DeviceLocationsConfig;
      customMapsConfig: CustomMapLayerConfig;
      clickObjectMenuConfig: ClickObjectMenuLayerConfig;
      onSearchResult: (data: SearchResultProps) => void;
      viewportDimensions?: () => { width: number; height: number };
      MapControls?: ({
        viewport,
      }: {
        viewport: Viewport;
      }) => Maybe<JSX.Element>;
      children: ReactNode;
    },
    ref: React.Ref<any>
  ) => {
    const { isGuest } = useContext(AuthContext);

    const {
      toggleLayer,
      visibleFIRM,
      visibleRaster: getVisibleRaster,
      visibleSavedViews: getVisibleSavedViews,
      visibleLayerIdsForGroup,
      measureToolDispatch,
      measureToolState,
      approximateBfeToolState,
      approximateBfeToolDispatch,
      geometryBounds,
    } = useContext(LayerContext);

    const [layersPanelVisible, toggleLayersPanel] = useState(false);
    const [showGeolocateBanner, setShowGeolocateBanner] = useState(false);
    const [savedViewIdToShowBannerFor, setSavedViewIdToShowBannerFor] =
      useState(savedViewsConfig.value?.[0]?.id);
    const visibleSavedViewIds = visibleLayerIdsForGroup("savedViews");
    useEffect(() => {
      if (
        visibleSavedViewIds.length === 1 &&
        savedViewIdToShowBannerFor === visibleSavedViewIds[0]
      ) {
        return;
      }
      setSavedViewIdToShowBannerFor(undefined);
    }, [JSON.stringify(visibleSavedViewIds)]);

    const visibleRaster = getVisibleRaster();

    const { latitude, longitude, selectedFirmId } = useQueryParams(account);

    const isTablet = useIsTabletWidth();

    const wrapperRef = useRef(null as Maybe<HTMLDivElement>);
    ref ||= useRef<any>();

    if (
      measureToolState.measureToolMode === "distance" ||
      measureToolState.measureToolMode === "raster" ||
      approximateBfeToolState?.mode === "on"
    ) {
      parcelConfig = {
        ...parcelConfig,
        interactive: { hover: false, click: false },
      };
      propertyConfig = {
        ...propertyConfig,
        interactive: { hover: false, click: false },
      };
      savedViewsConfig = {
        ...savedViewsConfig,
        interactive: { hover: false, click: false },
      };
      customMapsConfig = {
        ...customMapsConfig,
        interactive: { hover: false },
      };
    }

    const layers: LayerConfigs = {
      parcels: parcelConfig,
      firms: {
        firms: account.firms,
        onClick: crossSection => {
          approximateBfeToolDispatch?.({
            type: "toggleCrossSection",
            data: { crossSection },
          });
        },
        interactive: {
          click:
            approximateBfeToolState?.mode === "on" &&
            !approximateBfeToolState.disableMapInteractions,
          hover: approximateBfeToolState?.mode === "on",
        },
      },
      measure: {
        onClick: coordinates => {
          measureToolDispatch({
            type: "setCoordinates",
            data: { coordinates },
          });
        },
        interactive: {
          click:
            (measureToolState.measureToolMode === "distance" ||
              measureToolState.measureToolMode === "raster") &&
            !measureToolState.loading,
        },
      },
      raster: {
        rasters: account.rasters,
      },
      accountBoundary: {},
      marker: markerConfig,
      properties: propertyConfig,
      contour: {},
      cbrs: {},
      clickObjectMenu: clickObjectMenuConfig,
      customMaps: customMapsConfig,
      savedViews: savedViewsConfig,
      savedViewsCluster: {
        value: savedViewsConfig.value,
        onClick: async (lngLat: [number, number]) => {
          await zoomToPoint(
            { longitude: lngLat[0], latitude: lngLat[1] },
            viewport.zoom + 1
          );
        },
        interactive: {
          click: !!savedViewsConfig.interactive?.click,
          hover: false,
        },
      },
      deviceLocations: deviceLocationsConfig ?? {},
    };

    // While we show the marker based on the passed in values, not URL lat/long,
    // We need to set the zoom to 18 if there is a lat/long in the URL.
    // Otherwise, the map will render completely zoomed out and set the zoom to 0
    const defaultZoom = latitude && longitude ? 18 : undefined;

    const { viewport, setViewport, setViewportWithTransition } = useViewport({
      zoom: defaultZoom,
      latitude,
      longitude,
      bounds: account.bounds as Bounds,
      viewportDimensions,
    });

    // This code sets it up so that the layers panel disappears when click on the map on mobile
    useOutsideAlerter({
      ref: wrapperRef,
      onOutsideInteraction: () => toggleLayersPanel(false),
    });

    const handleLayersToggle = (evt: MouseEvent | KeyboardEvent) => {
      evt.preventDefault();
      toggleLayersPanel(!layersPanelVisible);
    };

    const baseMapStyle = account.baseMaps.find(
      map => map.id === visibleLayerIdsForGroup("baseMaps")[0]
    )?.mapboxStyle;

    const geolocateClicked = async () => {
      let geolocationPermissions = { state: "denied" };

      // the availability of the geolocation API is super spotty
      // for mobile iOS in particular for some reason - perhaps
      // specific phone settings? - and so we just blanket this
      // with some pokemon exception handling
      try {
        geolocationPermissions = await navigator.permissions.query({
          name: "geolocation",
        });
      } catch (error) {
        if (
          error instanceof Error &&
          (!error.message.includes(
            "Permissions::query does not support this API"
          ) ||
            !error.message.includes("The operation is not supported."))
        ) {
          captureException(error);
        }
      }

      if (geolocationPermissions.state === "denied") {
        setShowGeolocateBanner(true);
      }
    };

    const zoomToPoint = async (point: Point, zoom: number = 18) => {
      await onMapAvailable(ref as MutableRefObject<MapRef | null>, () => {
        setViewportWithTransition({
          ...point,
          zoom,
        });
      });
    };

    const zoomToBounds = async (bounds: Bounds) => {
      await onMapAvailable(ref as MutableRefObject<MapRef | null>, () => {
        setViewportWithTransition(
          {
            bounds,
          },
          true
        );
      });
    };

    useEffect(() => {
      if (geometryBounds) {
        void zoomToBounds(geometryBounds);
      } else if (markerConfig.value || parcelConfig.value?.point) {
        void zoomToPoint(markerConfig.value || parcelConfig.value?.point!);
      }
    }, [
      geometryBounds,
      markerConfig.value?.latitude,
      markerConfig.value?.longitude,
      parcelConfig.value?.point?.latitude,
      parcelConfig.value?.point?.longitude,
    ]);

    useEffect(() => {
      if (selectedFirmId) {
        toggleLayer({ group: "firms", id: selectedFirmId, isVisible: true });
      }
    }, [selectedFirmId]);

    // Older browsers, such as safari 14, don't support navigator.permissions.
    // If it is undefined, don't display the geolocate component.
    // Additionally, there were problems on the explore page with geolocation, so we have a guest-only check there
    const canGeolocate =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      !!navigator.permissions && (!!navigator.geolocation || !isGuest);

    const activeFloodLayerName =
      visibleFIRM()?.name ?? visibleRaster?.layerName;

    const hideNavigationControls =
      (measureToolState.measureToolMode === "raster" ||
        measureToolState.measureToolMode === "distance") &&
      isSmall();

    const visibleSavedViews = getVisibleSavedViews();

    return (
      <>
        <LayeredMap
          ref={ref}
          height="100%"
          width="100%"
          baseMapStyle={baseMapStyle}
          account={account}
          layers={layers}
          viewport={viewport}
          setViewport={setViewport}
          onSearchResult={onSearchResult}
        >
          {children}
          {showGeolocateBanner && !layersPanelVisible && (
            <ZoomToLocationBanner>
              <GeolocateBanner
                onClose={() => {
                  setShowGeolocateBanner(false);
                }}
              />
            </ZoomToLocationBanner>
          )}
          {!hideNavigationControls && !layersPanelVisible && (
            <NavigationContainer>
              {deviceLocationsConfig && (
                <UserDeviceLocationLegendControl
                  setViewport={setViewportWithTransition}
                  deviceLocationsConfig={deviceLocationsConfig}
                />
              )}

              {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
              {canGeolocate && (
                <MapTool>
                  <MapToolLabel>Show my location</MapToolLabel>
                  <ZoomToLocationButton onClick={geolocateClicked}>
                    <GeolocateControl
                      trackUserLocation={true}
                      fitBoundsOptions={{ maxZoom: 18, minZoom: 18 }}
                      style={{ position: "relative" }}
                    />
                  </ZoomToLocationButton>
                </MapTool>
              )}

              <MapTool
                hideLabel={measureToolState.measureToolMode === "selecting"}
              >
                <MapToolLabel>Measure</MapToolLabel>
                <MeasureToolLegendControl />
              </MapTool>

              {!!MapControls && <MapControls viewport={viewport} />}

              {/* NavigationControl defaults to position: absolute which doesn't work in a flex parent */}
              <NavigationControl
                style={{ position: "relative" }}
                showCompass={false}
              />

              <LegendControlContainer>
                {activeFloodLayerName && (
                  <ActiveFloodLayerBadge onClick={handleLayersToggle}>
                    {activeFloodLayerName}
                  </ActiveFloodLayerBadge>
                )}
                <LegendControl
                  data-testid="legendControl"
                  styleVariant="outlineLight"
                  onClick={handleLayersToggle}
                  aria-label="Toggle Map Layers panel"
                >
                  <Icon iconName="layers" color="contentPrimary" size={16} />
                </LegendControl>
              </LegendControlContainer>
            </NavigationContainer>
          )}
        </LayeredMap>

        <SavedViewBanner
          visibleSavedView={visibleSavedViews?.find(
            savedView => savedView.id === savedViewIdToShowBannerFor
          )}
          zoom={viewport.zoom}
        />
        {measureToolState.render?.()}
        {approximateBfeToolState?.render?.()}
        {layersPanelVisible && (
          <div ref={isTablet ? wrapperRef : null}>
            <LayersPanel
              close={handleLayersToggle}
              firms={account.firms}
              rasters={account.rasters}
              customMaps={account.customMaps}
              baseMaps={account.baseMaps}
              accountDocumentTypes={account.accountDocumentTypes}
              accountPropertyWarningDefinitions={
                account.accountPropertyWarningDefinitions
              }
              savedViews={account.savedViews}
              disableRasterToggle={measureToolState.loading}
              disableFirmToggle={approximateBfeToolState?.mode !== "off"}
            />
          </div>
        )}
      </>
    );
  }
);

export type FullPageMapContainerProps = Omit<
  ComponentProps<typeof FullPageMap>,
  "account" | "savedViewsConfig"
> & { savedViewsConfig: Omit<SavedViewsLayerConfig, "savedViews"> };

const LayerProviderWrapper = ({
  account,
  children,
}: {
  children: ReactNode;
  account: ComponentProps<typeof LayerContextProvider>["account"];
}) => {
  const contextProviderData = useContext(LayerContext);

  if (isEmpty(contextProviderData)) {
    return (
      <LayerContextProvider account={account}>{children}</LayerContextProvider>
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

const FullPageMapContainer: React.FC<FullPageMapContainerProps> =
  React.forwardRef(
    (
      {
        children,
        markerConfig,
        parcelConfig,
        propertyConfig,
        savedViewsConfig,
        deviceLocationsConfig,
        customMapsConfig,
        onSearchResult,
        viewportDimensions,
        MapControls,
      },
      ref
    ) => {
      const { isGuest } = useContext(AuthContext);
      const { loading, error, data } = useMapsForAccountQuery({
        variables: {
          filterHidden: propertyConfig.filterHidden ?? true,
          isGuest,
        },
        fetchPolicy: "cache-and-network",
      });

      if (loading || error || !data?.account?.bounds) {
        return <div />;
      }

      let finalSavedViewsConfig: SavedViewsLayerConfig = {
        ...savedViewsConfig,
        savedViews: [],
      };
      if (data.account.savedViews?.length) {
        finalSavedViewsConfig.savedViews = data.account.savedViews;
      }

      const finalCustomMapsConfig: CustomMapLayerConfig = {
        ...customMapsConfig,
        maps: data.account.customMaps,
      };

      const clickObjectMenuConfig: ClickObjectMenuLayerConfig = {
        interactive: { click: true, hover: true },
        maps: data.account.customMaps,
        savedViews: finalSavedViewsConfig.value,
      } satisfies ClickObjectMenuLayerConfig;

      return (
        <LayerProviderWrapper account={data.account}>
          <FullPageMap
            account={data.account}
            markerConfig={markerConfig}
            parcelConfig={parcelConfig}
            propertyConfig={propertyConfig}
            savedViewsConfig={finalSavedViewsConfig}
            deviceLocationsConfig={deviceLocationsConfig}
            customMapsConfig={finalCustomMapsConfig}
            clickObjectMenuConfig={clickObjectMenuConfig}
            ref={ref}
            onSearchResult={onSearchResult}
            viewportDimensions={viewportDimensions}
            MapControls={MapControls}
          >
            {children}
          </FullPageMap>
        </LayerProviderWrapper>
      );
    }
  );

export default FullPageMapContainer;
