import React from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Checkbox, Text } from "../../../../../../Inputs/react-hook-form";
import {
  SubmissionsBuilderFormDataStructure,
  InputFieldPath,
} from "../../types";
import {
  CheckboxWrapper,
  WidgetSettingsContainer,
} from "./__styles__/WidgetSettings";
import { FieldSettingConfig } from "./shared";

const DateWidgetSettings = ({
  fieldPath,
  isModuleInput,
}: {
  fieldPath: InputFieldPath;
  isModuleInput: boolean;
}) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig fieldPath={fieldPath} isModuleInput={isModuleInput} />
      <div>
        <Label text={"Helper text"} htmlFor={`${fieldPath}.helperText`} />
        <Text {...register(`${fieldPath}.helperText`)} />
      </div>
      <div>
        <Label text={"Placeholder text"} />
        <Text {...register(`${fieldPath}.placeholder`)} />
      </div>
      <CheckboxWrapper>
        <Checkbox {...register(`${fieldPath}.defaultToToday`)} />
        <Label text={"Default to current date"} />
      </CheckboxWrapper>
    </WidgetSettingsContainer>
  );
};

DateWidgetSettings.displayName = "DateWidgetSettings";

export default DateWidgetSettings;
