import React, { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { LinkTruncateWrapper } from "./__styles__/DynamicOverflowWrapper";
import { TooltipWithText } from "./Tooltip";

export const DynamicOverflowWrapper = ({
  Link,
  linkProps,
  text,
}: {
  Link: React.ElementType;
  linkProps: any;
  text: string;
}) => {
  const { width } = useWindowSize();
  const elementRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState<any>(false);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      // taken from this s/o https://stackoverflow.com/a/59364492/5094136
      setIsOverflow(element.scrollWidth > element.clientWidth);
    }
  }, [width]);

  return (
    <LinkTruncateWrapper ref={elementRef}>
      <Link {...linkProps}>
        <TooltipWithText
          hoverText={text}
          tooltipText={text}
          disable={!isOverflow}
        />
      </Link>
    </LinkTruncateWrapper>
  );
};
