import React, { useContext } from "react";
import { Layer, Source } from "react-map-gl";
import { LayerContext } from ".";
import {
  textFont,
  textColor,
  textHaloColor,
  textOpacity,
  customMapConfig,
} from "common-client/utils/mapLayers";
import { Tileset } from "../../../generated/graphql";
import { CUSTOM_MAPS_SOURCE_LAYER } from "common/utils/maps/constants";
import { NON_MAPBOX_TILESET_ID } from "common/constants";
import { NODE_ENV } from "common/env";
import { LayerId } from "../LayeredMap";
import { colors } from "../../../stitches.config";

const group = "customMaps";
export const CUSTOM_MAPS_SOURCE_ID_PREFIX = "custom-maps-";

export const getInteractiveLayerById: (id: string) => Array<LayerId> = (
  id: string
) => [`${id}-fill-color-polygons`, `${id}-fill-pattern-polygons`];

const CustomMapLayer = ({
  id,
  tileset,
  labelsVisibleOnMap,
}: {
  id: string;
  tileset: Tileset;
  labelsVisibleOnMap: boolean;
}) => {
  const { isLayerVisible } = useContext(LayerContext);
  const useLocalTileData =
    NODE_ENV !== "production" && tileset.mapboxId === NON_MAPBOX_TILESET_ID;

  const sourceLayer = useLocalTileData ? "src" : CUSTOM_MAPS_SOURCE_LAYER;

  return (
    <Source
      id={`${CUSTOM_MAPS_SOURCE_ID_PREFIX}${id}`}
      {...(useLocalTileData
        ? {
            tiles: [
              `${window.env.APPLICATION_URL}/api/tiles/customMaps/${id}/{z}/{x}/{y}`,
            ],
          }
        : { url: `mapbox://${tileset.mapboxId}` })}
      type="vector"
      minzoom={8}
      //@ts-ignore - the TS types for mapbox gl leave a lot to be desired
      promoteId="id"
    >
      <Layer
        id={`${id}-lines`}
        type="line"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility: isLayerVisible({ group, id }) ? "visible" : "none",
          "line-cap": "round",
          "line-join": "round",
        }}
        paint={{
          "line-color": customMapConfig.lineColor,
          "line-width": customMapConfig.getLineWidth({ defaultWidth: 2 }),
        }}
        filter={customMapConfig.includeLineStringFilter}
      />
      <Layer
        id={`${id}-fill-color-polygons`}
        type="fill"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility: isLayerVisible({ group, id }) ? "visible" : "none",
        }}
        paint={{
          "fill-color": customMapConfig.fillColor,
          "fill-opacity": customMapConfig.fillOpacity,
        }}
        filter={["!", customMapConfig.includeLineStringFilter]}
      />
      <Layer
        id={`${id}-fill-pattern-polygons`}
        type="fill"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility: isLayerVisible({ group, id }) ? "visible" : "none",
        }}
        // we have to have two separate polygon fills because
        // we can't use fill-pattern and fill-color in the same layer
        // see: https://github.com/mapbox/mapbox-gl-js/issues/8514
        paint={{
          "fill-pattern": customMapConfig.fillPattern,
        }}
        filter={["!", customMapConfig.includeLineStringFilter]}
      />
      <Layer
        id={`${id}-polygons`}
        type="line"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility: isLayerVisible({ group, id }) ? "visible" : "none",
        }}
        paint={{
          "line-color": customMapConfig.lineColor,
          "line-width": customMapConfig.getLineWidth({ defaultWidth: 2 }),
        }}
        filter={["!", customMapConfig.includeLineStringFilter]}
      />
      <Layer
        id={`${id}-line-labels`}
        type="symbol"
        source-layer={sourceLayer}
        layout={{
          "symbol-placement": "line-center",
          visibility:
            isLayerVisible({ group, id }) && labelsVisibleOnMap
              ? "visible"
              : "none",
          "text-field": customMapConfig.textField,
          "text-padding": 10,
          "text-size": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            0,
            10,
            10,
            15,
            15,
            20,
          ],
          "text-font": textFont,
        }}
        paint={{
          "text-color": textColor,
          "text-halo-color": textHaloColor,
          "text-opacity": textOpacity,
          "text-halo-width": 1,
        }}
        filter={customMapConfig.includeLineStringFilter}
      />
      <Layer
        id={`${id}-labels`}
        type="symbol"
        source-layer={sourceLayer}
        layout={{
          visibility:
            isLayerVisible({ group, id }) && labelsVisibleOnMap
              ? "visible"
              : "none",
          "text-field": customMapConfig.textField,
          "text-padding": 10,
          "text-size": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            0,
            10,
            10,
            15,
            15,
            20,
          ],
          "text-font": textFont,
          "symbol-avoid-edges": false,
        }}
        paint={{
          "text-color": textColor,
          "text-halo-color": textHaloColor,
          "text-opacity": textOpacity,
          "text-halo-width": 1,
        }}
        filter={["!", customMapConfig.includeLineStringFilter]}
      />
      <Layer
        id={`${id}-click`}
        type="line"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility: isLayerVisible({ group, id }) ? "visible" : "none",
        }}
        paint={{
          "line-opacity": [
            "case",
            ["==", ["feature-state", "click"], true],
            1,
            0,
          ],
          "line-color": colors.mapSelected.value,

          "line-width": customMapConfig.minLineWidth({ minWidth: 2 }),
        }}
      />
      <Layer
        id={`${id}-hover`}
        type="line"
        source-layer={sourceLayer}
        beforeId="ground"
        layout={{
          visibility: isLayerVisible({ group, id }) ? "visible" : "none",
        }}
        paint={{
          "line-opacity": [
            "case",
            ["==", ["feature-state", "click"], true],
            0,
            ["==", ["feature-state", "hover"], true],
            1,
            0,
          ],
          "line-color": colors.borderButtonOutline.value,
          "line-width": customMapConfig.minLineWidth({ minWidth: 2 }),
        }}
      />
    </Source>
  );
};

export default CustomMapLayer;
