import React from "react";
import { BaseMap, CustomMap, FIRM, LayerContext, SavedView } from "./layers";
import { FLOODZONES } from "common/utils/maps/index";
import Layer from "./Layer";
import LayerSection from "./LayerSection";

import { LegendItem, LegendSymbol } from "./__styles__/LayerControl";
import {
  AccountPropertyWarningDefinition,
  PropertyWarningGroup,
  Raster,
  RasterLayerSection,
} from "../../generated/graphql";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { groupBy, partition, sortBy } from "lodash";
import { AccountDocumentTypes } from "./__queries__/map";
import LayerGroup from "./LayerGroup";
import { orderPropertyWarningDefinitions } from "common-client/utils/warnings";
import { AuthContext } from "../Authorization/AuthContext";
import { group } from "./layers/savedViews";
import { generateDisplayName } from "common-client/utils/mapLayers";

const FDEM_ACCOUNT_ID = "ce0b1dc1-a2c0-40c0-be15-dcafd4c9cd58";

const CustomMapLegend = ({
  legend,
}: {
  legend: NonNullable<CustomMap["legend"]>;
}) => {
  return (
    <React.Fragment>
      {legend.map(({ color, label }) => {
        const transparent = color.toUpperCase() === "#FFFFFF";
        return (
          <LegendItem key={label}>
            <LegendSymbol
              style={{ backgroundColor: color }}
              transparent={transparent}
            />
            {/* Width of panel minus padding, minus space needed for the LegendSymbol */}
            <span style={{ maxWidth: "192px" }}>{label}</span>
          </LegendItem>
        );
      })}
    </React.Fragment>
  );
};

const FloodZonesLegend = () => {
  return (
    <React.Fragment>
      {FLOODZONES.map(({ color, zones, label, transparent = false }) => {
        const finalLabel = label ?? `Zone ${zones.join(", ")}`;

        return (
          <LegendItem key={color}>
            <LegendSymbol
              style={{ backgroundColor: color }}
              transparent={transparent}
            />
            {finalLabel}
          </LegendItem>
        );
      })}
      <LegendItem>
        <LegendSymbol floodway />
        Floodway
      </LegendItem>
      <LegendItem>
        <LegendSymbol limwa />
        LiMWA
      </LegendItem>
    </React.Fragment>
  );
};

const TerrainLegend = () => {
  return (
    <LegendItem>
      <LegendSymbol contour /> Contour lines
    </LegendItem>
  );
};

const CBRSLegend = () => {
  return (
    <React.Fragment>
      <LegendItem>
        <LegendSymbol cbrs /> CBRS Zone
      </LegendItem>
    </React.Fragment>
  );
};

interface LayerControlProps {
  firms: Array<FIRM>;
  rasters: Array<Raster>;
  customMaps: Array<CustomMap>;
  baseMaps: Array<BaseMap>;
  accountDocumentTypes: AccountDocumentTypes;
  accountPropertyWarningDefinitions: Array<AccountPropertyWarningDefinition>;
  savedViews?: Array<SavedView>;
  disableRasterToggle?: boolean;
  disableFirmToggle?: boolean;
}
const LayerControl = ({
  firms,
  rasters,
  customMaps,
  baseMaps,
  accountDocumentTypes,
  accountPropertyWarningDefinitions,
  savedViews,
  disableRasterToggle,
  disableFirmToggle,
}: LayerControlProps) => {
  const { visibleFIRM: getVisibleFIRM } = React.useContext(LayerContext);
  const { account } = React.useContext(AuthContext);

  const keyToTitleMap = {
    [PropertyWarningGroup.FIRM_WARNING]: "Firm warnings",
    [PropertyWarningGroup.CUSTOM_WARNING]: "Custom warnings",
    [PropertyWarningGroup.COMPLIANCE_WARNING]: "Compliance warnings",
    [PropertyWarningGroup.IMPROVEMENT_WARNING]: "SI/SD warnings",
    [PropertyWarningGroup.PRELIMINARY_DAMAGE_ASSESSMENT]: "Damage warnings",
    [PropertyWarningGroup.REPETITIVE_LOSS]: "Repetitive Loss warnings",
  };

  // Yes, we intentionally want to hide FIRM warnings.
  // FIRM-based warnings are weird because they apply to every parcel, even those without properties or certificates,
  // and so we can't let folks toggle them on because they'd expect to see FIRM warnings even on those parcels that don't have properties
  // (but you need properties to have warnings).
  // An example FIRM warning for which this applies is the SFHA_MAPPED_IN warning, which says that a property is mapped in to the special flood hazard area.
  // Since every A zone is a SFHA, you'd expect to see a warning dot on every parcel in an A zone, which we can't do since not ever parcel will have a property.
  // That being said, we *still* want to show any custom warnings that might've been associated with the FIRM group,
  // so we check the `isCustom` flag as well.
  const filteredWarningDefinitions = accountPropertyWarningDefinitions.filter(
    accountPropertyWarningDefinition =>
      accountPropertyWarningDefinition.isActive &&
      (accountPropertyWarningDefinition.group !==
        PropertyWarningGroup.FIRM_WARNING ||
        accountPropertyWarningDefinition.isCustom)
  );

  const warningDefinitionsbyGroup = filteredWarningDefinitions.reduce(
    (grouped, warningDefinition) => {
      const key = warningDefinition.group;
      grouped[key] ||= [];
      grouped[key].push(warningDefinition);
      return grouped;
    },
    {} as Record<PropertyWarningGroup, AccountPropertyWarningDefinition[]>
  );

  const groupedRasterLayers = groupBy(
    sortBy(rasters, "layerName"),
    "layerSection"
  );

  const showFloodLayerSection =
    firms.length > 0 || !!groupedRasterLayers[RasterLayerSection.FLOOD]?.length;

  const [objectCustomMaps, nonObjectCustomMaps] = partition(
    customMaps,
    ({ isObject }) => isObject
  );

  const showCustomLayerSection =
    nonObjectCustomMaps.length > 0 ||
    !!groupedRasterLayers[RasterLayerSection.CUSTOM]?.length;

  const visibleFIRM = getVisibleFIRM();

  const customLayerTitle =
    account?.id === FDEM_ACCOUNT_ID ? "FDEM layers" : "Custom layers";

  const SEVERITY_TO_COLOR = {
    low: ICON_COLORS.GREY,
    medium: ICON_COLORS.YELLOW,
    high: ICON_COLORS.RED,
  };

  return (
    <div>
      <LayerSection title="Base layers" testId="base-map-toggle-group">
        {baseMaps.map(({ name, id }) => (
          <Layer name={name} group="baseMaps" id={id} key={id} />
        ))}
      </LayerSection>
      {accountDocumentTypes.length > 0 && (
        <LayerSection title="Files" testId="documents-toggle-group">
          {accountDocumentTypes.map(accountDocumentType => (
            <Layer
              name={accountDocumentType.name}
              group="documents"
              id={accountDocumentType.id}
              key={accountDocumentType.id}
              color={accountDocumentType.color}
            />
          ))}
        </LayerSection>
      )}
      {filteredWarningDefinitions.length > 0 && (
        <LayerSection title="Warnings" testId="warnings-toggle-group">
          {Object.keys(warningDefinitionsbyGroup).map(key => {
            const castedKey = key as PropertyWarningGroup;
            return (
              <LayerGroup
                title={keyToTitleMap[castedKey]}
                sectionName="warnings"
                key={key}
                testId={`${key}-toggle-group`}
                group={`${castedKey}Warnings`}
              >
                {orderPropertyWarningDefinitions(
                  warningDefinitionsbyGroup[castedKey]
                ).map(accountPropertyWarningDefinition => (
                  <Layer
                    name={accountPropertyWarningDefinition.title}
                    ariaLabel={`${accountPropertyWarningDefinition.severity} warning severity - ${accountPropertyWarningDefinition.title}`}
                    group={`${castedKey}Warnings`}
                    id={accountPropertyWarningDefinition.id}
                    key={accountPropertyWarningDefinition.id}
                    Icon={() => (
                      <Icon
                        icon={Icons.INFORMATION_CIRCLE}
                        color={
                          SEVERITY_TO_COLOR[
                            accountPropertyWarningDefinition.severity
                          ]
                        }
                        aria-label={accountPropertyWarningDefinition.severity}
                      />
                    )}
                  />
                ))}
              </LayerGroup>
            );
          })}
        </LayerSection>
      )}
      <LayerSection title="Objects" testId="objects-toggle-group">
        <Layer
          name="Properties"
          group="buildings"
          id="properties"
          color="white"
        />

        {objectCustomMaps.map(({ name, id, legend }) => {
          return (
            <Layer
              name={name}
              group="customMaps"
              id={id}
              key={id}
              leftPadding={true}
            >
              {!!legend?.length && <CustomMapLegend legend={legend} />}
            </Layer>
          );
        })}
      </LayerSection>
      {showFloodLayerSection && (
        <LayerSection title="Flood layers" testId="flood-layers-group">
          {firms.map(({ name, id }) => {
            return (
              <Layer
                name={name}
                group="firms"
                id={id}
                key={id}
                disabled={disableFirmToggle}
              >
                <FloodZonesLegend />
              </Layer>
            );
          })}
          {!!visibleFIRM && (
            <Layer
              name="Panels"
              group="panels"
              id={visibleFIRM.id}
              key="panels"
            />
          )}

          {visibleFIRM?.components.baseFloodElevations.hasGeometries && (
            <Layer
              name="Base Flood Elevations"
              group="baseFloodElevations"
              id={visibleFIRM.id}
              key="baseFloodElevations"
            />
          )}
          {visibleFIRM?.components.crossSections.hasGeometries && (
            <Layer
              name="Cross Sections"
              group="crossSections"
              id={visibleFIRM.id}
              key="crossSections"
            />
          )}
          {visibleFIRM?.components.profileBaselines.hasGeometries && (
            <Layer
              name="Profile Baselines"
              group="profileBaselines"
              id={visibleFIRM.id}
              key="profileBaselines"
            />
          )}

          {groupedRasterLayers[RasterLayerSection.FLOOD]?.map(
            ({ layerName, id }) => {
              return (
                <Layer
                  name={layerName}
                  group="rasters"
                  id={id}
                  key={id}
                  disabled={disableRasterToggle}
                />
              );
            }
          )}
        </LayerSection>
      )}
      {showCustomLayerSection && (
        <LayerSection title={customLayerTitle} testId="custom-map-toggle-group">
          {nonObjectCustomMaps.map(({ name, id, legend }) => {
            return (
              <Layer name={`${name} Map`} group="customMaps" id={id} key={id}>
                {!!legend?.length && <CustomMapLegend legend={legend} />}
              </Layer>
            );
          })}
          {groupedRasterLayers[RasterLayerSection.CUSTOM]?.map(
            ({ layerName, id }) => {
              return (
                <Layer
                  name={layerName}
                  group="rasters"
                  id={id}
                  key={id}
                  disabled={disableRasterToggle}
                />
              );
            }
          )}
        </LayerSection>
      )}
      <LayerSection title="Land layers" testId="land-toggle-group">
        <Layer name="CBRS" group="topology" id="cbrs">
          <CBRSLegend />
        </Layer>

        <Layer name="Contour Lines" group="topology" id="contour">
          <TerrainLegend />
        </Layer>
        {groupedRasterLayers[RasterLayerSection.LAND]?.map(
          ({ layerName, id }) => {
            return (
              <Layer
                name={layerName}
                group="rasters"
                id={id}
                key={id}
                disabled={disableRasterToggle}
              />
            );
          }
        )}
      </LayerSection>
      {!!savedViews?.length && (
        <LayerSection title="Saved views" testId="saved-views-group">
          {savedViews.map(({ id, name, tableType }) => {
            return (
              <Layer
                name={generateDisplayName({ name, tableType })}
                group={group}
                id={id}
                key={id}
              />
            );
          })}
        </LayerSection>
      )}
    </div>
  );
};

export default LayerControl;
