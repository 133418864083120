import React from "react";

import { setTitle } from "../../utils/title";
import { Header } from "./Layout";
import { accountTitle } from "./utils";

import { AccessDeniedMessageContainer } from "./__styles__/AccessDeniedPage";
import { buildLink, getPath } from "common/routing";
import { useHistory } from "react-router";
import { EmptyState } from "../Common/EmptyState";
import { Page } from "./__styles__/Layout";
import { useGetAccessDeniedDataQuery } from "../../generated/graphql";
import { capitalize } from "lodash";

export enum ACCESS_DENIED_RESOURCE {
  PROPERTY = "property",
  FILE = "file",
}
const getLoginPageURL = (pathName: string) => {
  return `/login?prev=${encodeURIComponent(pathName)}`;
};

const AccessDeniedPage = ({
  resource,
}: {
  resource: ACCESS_DENIED_RESOURCE;
}) => {
  const history = useHistory();

  const { data } = useGetAccessDeniedDataQuery();

  const accountContactLink =
    data?.account?.contactInformation.email &&
    data.account.contactInformation.name ? (
      <a href={`mailto:${data.account.contactInformation.email}`}>
        {data.account.contactInformation.name}
      </a>
    ) : null;

  const body = (
    <>
      You may not have access to this {resource}, or it may have been removed.
      For more information,
      {accountContactLink && <> contact {accountContactLink} or</>} request
      help.
    </>
  );

  const icon = resource === "property" ? "missing-location" : "missing-file";

  setTitle(`No ${capitalize(resource)} Found | ${accountTitle()}`);

  return (
    <Page>
      <Header />

      <AccessDeniedMessageContainer>
        <EmptyState
          icon={icon}
          title={`This ${resource} is unavailable`}
          body={body}
          primaryButtonProps={{
            label: "Log in",
            onClick: () => {
              let pathName = location.pathname.includes("/file/")
                ? location.pathname.replace("/file/", "/files/")
                : location.pathname;

              const loginPageURL = getLoginPageURL(pathName);

              window.location.href =
                window.location.protocol +
                "//" +
                window.env.APPLICATION_DOMAIN +
                loginPageURL;
            },
          }}
          secondaryButtonProps={{
            label: "Explore map",
            onClick: () => history.push(getPath("explore")),
          }}
          buttonLinkProps={{
            label: "Get help",
            onClick: () => history.push(buildLink("getHelp")),
          }}
        />
      </AccessDeniedMessageContainer>
    </Page>
  );
};

export default AccessDeniedPage;
