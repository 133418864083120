import React, { useContext, useEffect } from "react";
import { ApolloError } from "@apollo/client";
import { UiSchema } from "@rjsf/utils";
import {
  DefaultedValuesArgs,
  DefaultedValueArgs,
  useCommonDefaultedValue,
  useCommonDefaultedValues,
} from "common-client/utils/submissions";
import { SubmissionFormContext } from "./Form";
import { InformationalTooltip } from "../Common/Tooltip";
import { SUBMISSION_CATEGORY } from "common/constants";
import { RECORDS_TAB_NAME } from "common/routing";
import { formatCoordinates } from "common/utils/coordinates";

export const useDefaultedValues = <
  Keys extends string = string,
  Values extends Record<Keys, any> = Record<Keys, any>,
  Schema extends Record<keyof Values, UiSchema | undefined> = Record<
    keyof Values,
    UiSchema | undefined
  >
>(
  args: DefaultedValuesArgs<Keys, Values, Schema>
) => {
  const { editing, defaultValues } = useContext(SubmissionFormContext);
  return useCommonDefaultedValues({
    ...args,
    useEffect,
    defaultValues,
    editing,
  });
};

export const useDefaultedValue = <Value,>(args: DefaultedValueArgs<Value>) => {
  const { editing, defaultValues } = useContext(SubmissionFormContext);
  return useCommonDefaultedValue({
    ...args,
    useEffect,
    defaultValues,
    editing,
  });
};

export const generateUpsertErrorMessage = (error: ApolloError) => {
  const message =
    error.message.includes("Invalid PDF file") ||
    error.message.includes("A record with the unique ID already exists.")
      ? error.message
      : "Your submission failed to process. Please try again.";

  return message;
};

export const castEmptyStringToNull = ({
  value,
  required,
}: {
  value: Maybe<string>;
  required: boolean;
}) => {
  // see commit message for why this `if` is necessary
  if (required) {
    return value === "" ? null : value;
  } else {
    return value;
  }
};

export const hasSummary = ({ summaries }: { summaries: Array<unknown> }) => {
  return summaries.length > 0;
};

type GetObjectDisplayProps = {
  property?: {
    fullAddress?: string | null;
    longitude: number;
    latitude: number;
  } | null;
  customMapGeometry?: {
    label: string;
  } | null;
};

export const getObjectDisplay = ({
  property,
  customMapGeometry,
}: GetObjectDisplayProps) => {
  if (property) {
    return property.fullAddress ?? formatCoordinates({ ...property });
  } else if (customMapGeometry) {
    return customMapGeometry.label;
  }
  return undefined;
};

export const getTooltip = ({ uiSchema }: { uiSchema?: UiSchema }) => {
  const tooltipText = uiSchema?.["ui:tooltipText"];
  if (!tooltipText) {
    return;
  }
  return <InformationalTooltip tooltipText={tooltipText} />;
};

export const SUBMISSION_CATEGORY_TO_TAB = {
  [SUBMISSION_CATEGORY.PERMITTING]: RECORDS_TAB_NAME.PERMITS,
  [SUBMISSION_CATEGORY.INSPECTIONS]: RECORDS_TAB_NAME.INSPECTIONS,
  [SUBMISSION_CATEGORY.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE]:
    RECORDS_TAB_NAME.IMPROVEMENTS,
  [SUBMISSION_CATEGORY.GRANTS]: RECORDS_TAB_NAME.GRANTS,
  [SUBMISSION_CATEGORY.PAYMENTS]: RECORDS_TAB_NAME.PAYMENTS,
  [SUBMISSION_CATEGORY.MAILINGS]: RECORDS_TAB_NAME.MAILINGS,
};
