import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { RESOURCE_NAME } from "common/authorization";
import { buildLink } from "common/routing";
import { createTracker } from "../../../utils/tracking";
import { AuthContext } from "../../Authorization/AuthContext";

import { DocumentLink, DocumentAnchor } from "./__styles__/ViewDocumentLink";

interface Props {
  documentUploadId: string;
  prevLocation?: string;
  isDocumentUploadField?: boolean;
  disabled?: boolean;
}

export const ViewDocumentLink = ({
  documentUploadId,
  prevLocation,
  children,
  isDocumentUploadField = false,
  disabled = false,
}: React.PropsWithChildren<Props>) => {
  const { authorized } = useContext(AuthContext);
  const canViewDocumentDetail = authorized({
    resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
    permission: "view_details",
  });
  const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      event.preventDefault();
      return;
    } else {
      createTracker("View Document clicked");
    }
  };

  if (canViewDocumentDetail) {
    return (
      <DocumentLink
        as={Link}
        tabIndex={0}
        onClick={onClickHandler}
        disabled={disabled}
        documentUploadField={isDocumentUploadField}
        to={{
          pathname: buildLink("documentUploadDetail", {
            id: documentUploadId,
          }),
          state: { prevLocation },
        }}
        {...(isDocumentUploadField ? { target: "_blank" } : {})}
        data-testid="view-document-link"
      >
        {children}
      </DocumentLink>
    );
  } else {
    return (
      <DocumentAnchor
        as={"a"}
        disabled={disabled}
        tabIndex={0}
        key={documentUploadId}
        href={buildLink("documentUploadFile", {
          id: documentUploadId,
        })}
        onClick={onClickHandler}
        target="_blank"
        data-testid="view-document-link"
      >
        {children}
      </DocumentAnchor>
    );
  }
};
