import React, {
  createContext,
  MouseEvent,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import DropdownMenu from "../Inputs/DropdownMenu";
import { User } from "common/authorization/customPolicies";
import { Account, Admin, SubmissionCategory } from "../../generated/graphql";
import { getPublicAccountPortalBaseURL } from "common/utils/url";
import { getPath } from "common/routing";
import { track } from "../../utils/tracking";
import { Icon } from "../Common/Icons/LucideIcons";

import {
  AccountInfo,
  AccountLogo,
  AccountTitle,
  Container,
  ItemWrapper,
  HomeButton,
  Label,
  StyledNavLink,
  BottomNav,
  Expander,
  RecordsHoveredCategory,
  RecordsHoveredCategoryContainer,
  RecordsHoveredCategoryLink,
} from "./__styles__/SideNav";
import { AuthContext } from "../Authorization/AuthContext";
import { FEATURE_FLAG_NAME } from "common/constants";
import { Body } from "../Common/Typography";
import { reducer } from "./useSideNavReducer";
import { omit } from "lodash";
import { useLocation } from "react-router";
import { colors } from "../../stitches.config";

const LabelText = ({ children }: { children: ReactNode }) => {
  return (
    <Body size="default" type="regular" color="contentPrimaryDark" as="span">
      {children}
    </Body>
  );
};

type SideNavWidthContextType = {
  width: number;
};
export const SideNavWidthContext = createContext<SideNavWidthContextType>({
  width: 0,
});

const actions = [
  {
    label: "Help center",
    href: "https://forerunner.helpscoutdocs.com/",
    target: "_blank",
  },
  {
    label: "Release notes",
    href: "https://headwayapp.co/forerunner-changelog",
    target: "_blank",
  },
];

const PublicAccountPortal = ({
  subdomain,
  expanded,
}: {
  subdomain: string;
  expanded: boolean;
}) => {
  const { account } = useContext(AuthContext);

  if (!account?.publicPortal.enabled) return null;

  const link = getPublicAccountPortalBaseURL({
    subdomain: subdomain,
    protocol: window.location.protocol,
    appDomain: `${window.env.APPLICATION_DOMAIN}`,
  });

  return (
    <StyledNavLink
      as="a"
      href={link}
      data-testid="public-account-portal-link"
      target="_blank"
      onClick={() => track("Public website button clicked")}
    >
      <Label expanded={expanded}>
        <Icon color="contentPrimaryDark" iconName="panels-top-left" size="16" />
        <LabelText>Public site</LabelText>
      </Label>
    </StyledNavLink>
  );
};

const trackNavLinkClick = (location: string) => {
  track("Sidetab nav clicked", { location });
};

export interface SideNavProps {
  user?: Omit<User, "role">;
  account?: Pick<Account, "logoUrl" | "name"> & {
    publicPortal: Pick<Account["publicPortal"], "subdomain">;
    submissionTypes: Pick<Account["submissionTypes"][0], "category">[];
  };
  admin?: Pick<Admin, "id">;
  setSideNavWidth?: (width: number) => void;
}

const SideNav = ({ user, account, admin, setSideNavWidth }: SideNavProps) => {
  const { pathname } = useLocation();

  const [{ sideNavExpanded, recordsExpanded }, dispatch] = useReducer(reducer, {
    sideNavExpanded: false,
    recordsExpanded: false,
  });
  // This is only needed for updating aria-hidden for unit tests,
  // so not including in the reducer
  const [recordsHovered, setRecordsHovered] = useState(false);

  const sideNavRef = useRef<HTMLDivElement>(null);
  const { isFeatureEnabled } = useContext(AuthContext);

  useEffect(() => {
    if (sideNavRef.current && setSideNavWidth) {
      setSideNavWidth(sideNavRef.current.offsetWidth);
    }
  }, [sideNavExpanded]);

  const HelpIcon = ({
    onClick,
  }: {
    onClick: (event: MouseEvent<HTMLElement>) => void;
  }) => (
    <ItemWrapper onClick={onClick}>
      <Label expanded={sideNavExpanded}>
        <Icon color="contentPrimaryDark" iconName="help-circle" size="16" />
        <LabelText>Help Center</LabelText>
      </Label>
    </ItemWrapper>
  );

  if (!account) {
    return null;
  }

  const allCategoryToData = {
    logs: {
      url: getPath("logs"),
      mixpanel: "logs",
      display: "Logs",
    },
    [SubmissionCategory.PERMITTING]: {
      url: getPath("submissions"),
      mixpanel: "permitting",
      display: "Permits",
    },
    [SubmissionCategory.INSPECTIONS]: {
      url: getPath("inspections"),
      mixpanel: "inspections",
      display: "Inspections",
    },
  };

  const categories = new Set(
    account.submissionTypes.map(type => type.category)
  );

  // Right now, every account has logs. If/when the logs to submissions project is played,
  // we can't default to showing logs as an option. When that happens, include a unit test
  // that checks the records section doesn't show if no record categories exist for the account
  const recordCategories = [
    allCategoryToData.logs,
    ...Object.entries(omit(allCategoryToData, "logs"))
      .filter(([category]) => categories.has(category as SubmissionCategory))
      .map(([_, data]) => data),
  ];

  const onRecordTab = Object.values(recordCategories)
    .map(category => category.url)
    .includes(pathname);

  return (
    <Container ref={sideNavRef}>
      <AccountInfo>
        <HomeButton to={getPath("map")}>
          {account.logoUrl ? (
            <AccountLogo
              src={account.logoUrl}
              alt={`${account.name} Logo`}
            ></AccountLogo>
          ) : (
            <AccountTitle>{account.name[0]}</AccountTitle>
          )}
        </HomeButton>
        <ItemWrapper>
          <Expander onClick={() => dispatch({ toggle: "sideNavExpanded" })}>
            <Icon
              color="contentPrimaryDark"
              iconName={sideNavExpanded ? "chevron-left" : "chevron-right"}
              size="16"
              testId="side-nav-expander"
            />
          </Expander>
        </ItemWrapper>
      </AccountInfo>

      <StyledNavLink
        to={getPath("map")}
        onClick={() => trackNavLinkClick("map")}
      >
        <Label expanded={sideNavExpanded}>
          <Icon color="contentPrimaryDark" iconName="globe-2" size="16" />
          <LabelText>Map</LabelText>
        </Label>
      </StyledNavLink>
      {isFeatureEnabled(FEATURE_FLAG_NAME.PROPERTY_TABLE) && (
        <StyledNavLink
          to={getPath("properties")}
          onClick={() => trackNavLinkClick("properties")}
        >
          <Label expanded={sideNavExpanded}>
            <Icon color="contentPrimaryDark" iconName="home" size="16" />
            <LabelText>Properties</LabelText>
          </Label>
        </StyledNavLink>
      )}

      {recordCategories.length > 0 && (
        <ItemWrapper
          expanded={recordsExpanded}
          data-testid="records-item-wrapper"
          // Because the icon isn't clickable when sidenav is collapsed,
          // we use default cursor to signify no click behavior
          style={{
            cursor: sideNavExpanded ? "pointer" : "default",
            backgroundColor:
              !recordsExpanded && onRecordTab
                ? colors.bgUiInteractionDark.value
                : "transparent",
          }}
        >
          <Label
            expanded={sideNavExpanded}
            onClick={() => {
              if (sideNavExpanded) {
                dispatch({ toggle: "recordsExpanded" });
              }
            }}
          >
            <Icon
              color="contentPrimaryDark"
              iconName="library"
              size="16"
              testId="records-icon"
              onMouseEnter={() => setRecordsHovered(true)}
              onMouseLeave={() => setRecordsHovered(false)}
            />
            {sideNavExpanded && <LabelText>Records</LabelText>}
            {sideNavExpanded && (
              <Icon
                color="contentPrimaryDark"
                iconName={recordsExpanded ? "chevron-up" : "chevron-down"}
                size="16"
              />
            )}
          </Label>

          {!sideNavExpanded && (
            <RecordsHoveredCategoryContainer
              role="menu"
              aria-hidden={!recordsHovered}
            >
              <RecordsHoveredCategory>
                <LabelText>Records</LabelText>
              </RecordsHoveredCategory>
              {recordCategories.map((category, index) => (
                <RecordsHoveredCategoryLink
                  key={index}
                  to={category.url}
                  onClick={() => trackNavLinkClick(category.mixpanel)}
                  last={index === recordCategories.length - 1}
                >
                  <LabelText>{category.display}</LabelText>
                </RecordsHoveredCategoryLink>
              ))}
            </RecordsHoveredCategoryContainer>
          )}

          {recordsExpanded && (
            <>
              {recordCategories.map((category, index) => (
                <StyledNavLink
                  key={index}
                  to={category.url}
                  onClick={() => trackNavLinkClick(category.mixpanel)}
                >
                  <Label expanded style={{ paddingLeft: "40px" }}>
                    <LabelText>{category.display}</LabelText>
                  </Label>
                </StyledNavLink>
              ))}
            </>
          )}
        </ItemWrapper>
      )}

      <StyledNavLink
        to={getPath("documentUploads")}
        onClick={() => trackNavLinkClick("files")}
      >
        <Label expanded={sideNavExpanded}>
          <Icon color="contentPrimaryDark" iconName="file-text" size="16" />
          <LabelText>Files</LabelText>
        </Label>
      </StyledNavLink>
      <StyledNavLink
        to={getPath("settings")}
        onClick={() => trackNavLinkClick("settings")}
      >
        <Label expanded={sideNavExpanded}>
          <Icon color="contentPrimaryDark" iconName="settings" size="16" />
          <LabelText>Settings</LabelText>
        </Label>
      </StyledNavLink>

      <BottomNav>
        <PublicAccountPortal
          subdomain={account.publicPortal.subdomain}
          expanded={sideNavExpanded}
        />
        <DropdownMenu
          position="above-right"
          actions={actions}
          customButton={HelpIcon}
        />
        {user && (
          <StyledNavLink as="a" href={getPath("logout")}>
            <Label expanded={sideNavExpanded} border>
              <Icon
                color="contentPrimaryDark"
                iconName="arrow-right-square"
                size="16"
              />
              <LabelText>Log Out</LabelText>
            </Label>
          </StyledNavLink>
        )}
        {admin && (
          <StyledNavLink as="a" href={getPath("adminAccounts")}>
            <Label expanded={sideNavExpanded} border>
              <Icon
                color="contentPrimaryDark"
                iconName="arrow-right-square"
                size="16"
              />
              <LabelText>Accounts</LabelText>
            </Label>
          </StyledNavLink>
        )}
      </BottomNav>
    </Container>
  );
};
export default SideNav;
