import React, { ReactNode } from "react";
import {
  Fieldset,
  HelperTextWrapper,
  HelperTextSpan,
} from "./__styles__/Wrapper";

import Label, { LabelProps } from "./Label";

export type WrapperProps = {
  name: string;
  children: ReactNode;
  label?: undefined | Maybe<string>;
  error?: Maybe<string>;
  addon?: ReactNode;
  expandWithError?: boolean;
  tooltip?: ReactNode;
  compactLabel?: LabelProps["compact"];
  labelTabIndex?: LabelProps["tabIndex"];
  labelSize?: LabelProps["size"];
  helperText?: Maybe<string>;
} & Pick<LabelProps, "description" | "disabled" | "required">;

const HelperText = ({
  errorText,
  defaultText,
}: {
  errorText?: Maybe<string>;
  expandWithError?: boolean;
  defaultText?: Maybe<string>;
}) => {
  if (!errorText && !defaultText) {
    return null;
  }

  return (
    <HelperTextWrapper>
      <HelperTextSpan isError={!!errorText}>
        {errorText ?? defaultText}
      </HelperTextSpan>
    </HelperTextWrapper>
  );
};

export default ({
  label,
  compactLabel = false,
  name,
  error,
  addon,
  labelTabIndex,
  children,
  required,
  description,
  disabled,
  expandWithError = true,
  tooltip,
  labelSize,
  helperText,
}: WrapperProps) => {
  return (
    <Fieldset hasTooltip={!!tooltip} error={!!error}>
      {!!label && (
        <Label
          text={label}
          compact={compactLabel}
          htmlFor={name}
          tabIndex={labelTabIndex}
          required={required}
          description={description}
          disabled={disabled}
          size={labelSize}
        />
      )}
      {tooltip}
      {addon}
      {children}
      <HelperText
        errorText={error}
        expandWithError={expandWithError}
        defaultText={helperText}
      />
    </Fieldset>
  );
};
