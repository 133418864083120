import React, { useContext } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { omit } from "lodash";
import { Button } from "../../Common/Button";

import {
  Checkbox,
  Datepicker,
  Number,
  Select,
  Text,
} from "../../Inputs/react-hook-form";
import { Box, FlexRow } from "../../Common/__styles__/Layout";
import FullPageLoadingScreen from "../../Common/FullPageLoadingScreen";
import {
  CoastalADetermination,
  DatumConversionOffsetPurpose,
  GetAccountInfoQuery,
  useGetAccountInfoQuery,
  useUpdateAccountLogoMutation,
  useUpdateAccountMutation,
} from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { track } from "../../../utils/tracking";
import { ACCOUNT_INFO } from "./__queries__/general";
import Divider from "../../Common/Divider";
import { SettingsTooltip } from "./Tooltip";
import { InsufficientPermissionsBanner } from "./InsufficientPermissionsBanner";
import FeatureFlags from "./FeatureFlags";
import { Label } from "../../Inputs";

import {
  AccountNameContainer,
  CheckboxRow,
  Logo,
  LogoContainer,
  LogoHelpText,
  LogoLabel,
  UpdateLogoContainer,
} from "./__styles__/General";
import { AccountTitle } from "../../SideNav/__styles__/SideNav";
import { Footer, Header, HeaderTitle, Section } from "../__styles__/Content";

interface DroppedFile extends File {
  path?: string; //only marked optional to appease unavoidable, too strict type in useDropzone
}

interface FormStructure {
  accountName: string;
  initialFIRMDate: Maybe<string>;
  coastalADetermination: CoastalADetermination;
  datumConversionOffsetPurpose: DatumConversionOffsetPurpose;
  datumConversionOffset: Maybe<number>;
  repetitiveLossEnabled: boolean;
}

const GeneralSettingsForm = ({
  account,
  useDropzone,
}: {
  account: NonNullable<GetAccountInfoQuery["account"]>;
  useDropzone: (
    args: NonNullable<Parameters<typeof useReactDropzone>[0]>
  ) => ReturnType<typeof useReactDropzone>;
}) => {
  const { admin, authorized } = useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const { register, handleSubmit, control } = useForm<FormStructure>({
    defaultValues: {
      accountName: account.name,
      initialFIRMDate: account.initialFIRMDate,
      coastalADetermination: account.coastalADetermination,
      datumConversionOffsetPurpose: account.datumConversionOffsetPurpose,
      datumConversionOffset: account.datumConversionOffset,
      repetitiveLossEnabled: account.repetitiveLossEnabled,
    },
  });

  const [updateAccountMutation, { loading }] = useUpdateAccountMutation({
    onCompleted: () => {
      addSuccessToast("Your account settings were successfully updated.");
    },
    onError: () => {
      addErrorToast(
        "There was a problem updating your account settings. Please try again or contact support@withforerunner.com"
      );
    },
  });

  const canUpdateAccountInformation = authorized({
    resource: RESOURCE_NAME.ACCOUNT,
    permission: "update",
  });

  const onDropAccepted = async (acceptedFiles: DroppedFile[]) => {
    await updateAccountLogo({
      variables: { data: { logo: acceptedFiles[0] } },
    });
  };

  const { getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    // Value is in bytes, max is 5mb
    maxSize: 1048576 * 5,
    multiple: false,
    onDropAccepted,
  });

  const [updateAccountLogo] = useUpdateAccountLogoMutation({
    refetchQueries: [{ query: ACCOUNT_INFO, variables: {} }],
    onCompleted: () => {
      addSuccessToast("Your account logo was successfully updated.");
    },
    onError: error => {
      addErrorToast(error.message);
    },
  });

  return (
    <>
      {!canUpdateAccountInformation && <InsufficientPermissionsBanner />}
      <Box>
        <Header>
          <HeaderTitle>Account information</HeaderTitle>
        </Header>
        <form
          onSubmit={handleSubmit(async data => {
            const formData = omit(data, ["accountName"]);

            track("Account Settings Updated");

            await updateAccountMutation({
              variables: { data: { id: account.id, ...formData } },
            });
          })}
        >
          <Section>
            <div>
              <LogoLabel>Account logo</LogoLabel>
              <LogoContainer>
                {account.logoUrl ? (
                  <Logo src={account.logoUrl}></Logo>
                ) : (
                  <AccountTitle>{account.name[0]}</AccountTitle>
                )}
                <UpdateLogoContainer>
                  <FlexRow style={{ gap: "8px" }}>
                    <input {...getInputProps()} />
                    <Button
                      styleVariant="outlineLight"
                      size="small"
                      disabled={!canUpdateAccountInformation}
                      onClick={() => {
                        open();
                        track("User uploaded a new logo");
                      }}
                    >
                      Upload image
                    </Button>
                    {account.logoUrl && (
                      <Button
                        styleVariant="ghostAlert"
                        size="small"
                        onClick={() =>
                          updateAccountLogo({
                            variables: { data: { logo: null } },
                          })
                        }
                        leftIconName="trash"
                      >
                        Remove image
                      </Button>
                    )}
                  </FlexRow>
                  <LogoHelpText>
                    Logo must be a .jpg or .png and be below 5mb and have an
                    aspect ratio of 1:1.
                  </LogoHelpText>
                </UpdateLogoContainer>
              </LogoContainer>
            </div>
            <AccountNameContainer>
              <Text
                disabled
                label="Account name"
                size="medium"
                tooltip={
                  <SettingsTooltip contentText="If you would like to update your account name, please reach out to support@withforerunner.com" />
                }
                {...register("accountName")}
              />
            </AccountNameContainer>
          </Section>
          <Divider margins="16px 0px" />
          <Section grid>
            <Datepicker
              control={control as unknown as Control<FieldValues, any>}
              name={"initialFIRMDate"}
              label={"Initial FIRM date"}
              disabled={!canUpdateAccountInformation}
            />
            {admin && (
              <>
                <Select
                  label="Coastal A determination"
                  options={[
                    {
                      label: "Disabled",
                      value: CoastalADetermination.DISABLED,
                    },
                    { label: "Manual", value: CoastalADetermination.MANUAL },
                    {
                      label: "Automatic",
                      value: CoastalADetermination.AUTOMATIC,
                    },
                  ]}
                  name="coastalADetermination"
                  control={control}
                />
                <Select
                  label="Datum conversion offset purpose"
                  options={[
                    {
                      label: "Fallback",
                      value: DatumConversionOffsetPurpose.FALLBACK,
                    },
                    {
                      label: "Override",
                      value: DatumConversionOffsetPurpose.OVERRIDE,
                    },
                  ]}
                  name="datumConversionOffsetPurpose"
                  control={control}
                />
                <Number
                  name="datumConversionOffset"
                  label="Datum conversion offset"
                  register={register}
                  step="any"
                />
                <CheckboxRow>
                  <Checkbox
                    id="repetitiveLossEnabled"
                    {...register("repetitiveLossEnabled")}
                  />
                  <Label
                    htmlFor="repetitiveLossEnabled"
                    style={{ marginBottom: 0 }}
                    text="Repetitive loss enabled"
                  />
                </CheckboxRow>
              </>
            )}
          </Section>
          <Divider margins="16px 0px" />
          <Section>
            <Button
              styleVariant="primary"
              size="medium"
              type="submit"
              disabled={!canUpdateAccountInformation || loading}
            >
              Update account info
            </Button>
          </Section>
        </form>
        <Footer />
      </Box>
    </>
  );
};

const GeneralSettings = ({
  useDropzone = useReactDropzone,
}: {
  useDropzone?: (
    args: NonNullable<Parameters<typeof useReactDropzone>[0]>
  ) => ReturnType<typeof useReactDropzone>;
}) => {
  const { authorized } = useContext(AuthContext);
  const { loading, data, error } = useGetAccountInfoQuery();

  if (loading || !data) return <FullPageLoadingScreen />;

  const account = data.account;
  if (error || !account) return <div>Error loading account.</div>;

  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <GeneralSettingsForm account={account} useDropzone={useDropzone} />
      {authorized({
        resource: RESOURCE_NAME.FEATURE_FLAG,
        permission: "update",
      }) && <FeatureFlags />}
    </div>
  );
};

export default GeneralSettings;
