import React, { useContext, useEffect, useMemo, useRef } from "react";
import {
  EventFeature,
  findRelevantFeature,
  LayerConfig,
  LayerHook,
  LayerHookBuilder,
  LayerId,
  Property,
  SourceId,
} from "./types";
import PropertiesLayer, {
  buildingsLayerId,
  documentsLayerId,
  interactiveLayerIds as allInteractiveLayerIds,
  propertyBuildingSourceId,
  propertyDocumentSourceId,
  propertyWarningSourceId,
  warningsLayerId,
} from "../layers/properties";
import { LayerContext } from "../layers";
import {
  buildingsGroup,
  documentsGroup,
  warningsGroups,
} from "../layers/properties";
import { formatCoordinates } from "common/utils/coordinates";
import { makePropertyFromFeature } from "./utils";
import { WarningGroup } from "common-client/utils/layerState";
import { flatten } from "lodash";
import { getInteractiveLayerById } from "../layers/customMap";

export type PropertyLayerConfig = LayerConfig<
  { id: Maybe<string> },
  Property
> & {
  filterHidden?: boolean;
  geocacheParcelId?: Maybe<string>;
};

const propertyHook: LayerHookBuilder<PropertyLayerConfig> = ({
  config,
  helpers,
  mapLoaded,
}) => {
  const { visibleLayerIdsForGroup } = useContext(LayerContext);
  const hoveredProperty = useRef<Maybe<string>>(null);
  const clickedProperty = useRef<Maybe<string>>(null);

  const buildingsAreVisible = !!visibleLayerIdsForGroup(buildingsGroup)[0];
  const documentsAreVisible = !!visibleLayerIdsForGroup(documentsGroup)[0];

  const warningsAreVisible = warningsGroups.some(
    group => visibleLayerIdsForGroup(group as WarningGroup)[0]
  );

  let source: SourceId | undefined = undefined;
  if (documentsAreVisible) {
    source = propertyDocumentSourceId;
  } else if (warningsAreVisible) {
    source = propertyWarningSourceId;
  } else if (buildingsAreVisible) {
    source = propertyBuildingSourceId;
  }

  useEffect(() => {
    if (!mapLoaded) {
      return;
    }
    const targetId = config.value?.id;

    if (clickedProperty.current && clickedProperty.current !== targetId) {
      helpers.removeFeatureState({
        id: clickedProperty.current,
        field: "click",
        source: source!,
      });
    }

    if (targetId) {
      clickedProperty.current = targetId;
      helpers.setFeatureState({
        id: targetId,
        field: "click",
        source: source!,
      });
    }
  }, [config.value?.id, mapLoaded]);

  const removeHoverState = () => {
    if (hoveredProperty.current) {
      helpers.removeFeatureState({
        id: hoveredProperty.current,
        field: "hover",
        source: source!,
      });

      hoveredProperty.current = null;
    }
  };

  const filteredInteractiveLayerIds: LayerId[] =
    config.interactive?.hover || config.interactive?.click
      ? [buildingsLayerId, documentsLayerId, warningsLayerId]
      : [];

  return useMemo<LayerHook>(() => {
    return {
      zIndex: 0,
      interactiveLayerIds: filteredInteractiveLayerIds,
      render: () => {
        return (
          <PropertiesLayer
            key={source ?? "properties-layer"}
            filterHidden={config.filterHidden}
            geocacheParcelId={config.geocacheParcelId}
          />
        );
      },
      canHandleClick: () => !!config.interactive?.click,
      canHandleHover: () => !!config.interactive?.hover,
      onClick: ({ event }) => {
        const propertyFeature = findRelevantFeature(
          event,
          allInteractiveLayerIds
        );
        const parcelFeature = (
          event.features as EventFeature[] | undefined
        )?.find(({ layer }) => layer.id === "parcels");

        if (
          clickedProperty.current &&
          clickedProperty.current !== propertyFeature?.id
        ) {
          helpers.removeFeatureState({
            id: clickedProperty.current,
            field: "click",
            source: source!,
          });
        }
        if (propertyFeature) {
          clickedProperty.current = propertyFeature.id;
          helpers.setFeatureState({
            id: propertyFeature.id,
            field: "click",
            source: source!,
          });

          config.onClick?.({
            ...makePropertyFromFeature(propertyFeature),
            parcel: parcelFeature
              ? {
                  id: parcelFeature.properties.id,
                  parcelNumber: parcelFeature.properties.parcelNumber,
                  ...parcelFeature.properties,
                }
              : null,
          });
        }

        return !!propertyFeature;
      },
      onHover: ({ event }) => {
        removeHoverState();
        const feature = findRelevantFeature(event, allInteractiveLayerIds);

        if (feature) {
          helpers.setCursor("pointer");
          helpers.emit({
            type: "setTooltip",
            data: {
              ...event.offsetCenter,
              text:
                feature.properties.address ??
                formatCoordinates({
                  latitude: feature.geometry.coordinates[1],
                  longitude: feature.geometry.coordinates[0],
                }),
            },
          });

          const featureId = feature.id;

          helpers.setFeatureState({
            id: featureId,
            field: "hover",
            source: source!,
          });

          hoveredProperty.current = featureId;
        } else {
          const parcel = findRelevantFeature(event, "parcels");
          const visibleCustomMapIds = visibleLayerIdsForGroup("customMaps");
          const allInteractiveLayerIds: Array<LayerId> = flatten(
            visibleCustomMapIds.map(getInteractiveLayerById)
          );
          const customMapFeature = findRelevantFeature(
            event,
            allInteractiveLayerIds
          );
          if (!parcel && !customMapFeature) {
            helpers.setCursor("grab");
          }
        }
      },
      onFocusLost: () => {
        removeHoverState();
        helpers.emit({ type: "removeTooltip", data: {} });
      },
    };
  }, [config]);
};

export default propertyHook;
