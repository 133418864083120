import React, { useContext } from "react";
import { AddressPanelTabProps } from "..";
import {
  SubmissionCategory,
  SubmissionIntakeSource,
  useGetSubmissionsForPropertyQuery,
} from "../../../generated/graphql";
import { EmptyState } from "../../Common/EmptyState";
import MissingProperty from "../MissingProperty";
import { Layout } from "../Records";
import ListItem from "./ListItem";
import { CreateSubmissionButton } from "../../Common/CreateSubmissionButton";
import { canCreateInternalSubmission } from "common/authorization/customPolicies";
import { AuthContext } from "../../Authorization/AuthContext";
import { AddressPanelContext } from "../AddressPanelContext";
import { lowerCase } from "lodash";
import { OBJECT_TYPE } from "common/constants";
import { createSubmissionRedirect } from "../../Common/CreateSubmissionButton/utils";
import { useHistory } from "react-router";
import { getObjectDisplay } from "../../Submissions/utils";

export default ({
  address,
  latitude,
  longitude,
  title,
  category,
}: AddressPanelTabProps & { category: SubmissionCategory; title: string }) => {
  const { user, admin } = useContext(AuthContext);
  const { property, loading: loadingPropertyId } =
    useContext(AddressPanelContext);
  const history = useHistory();

  const propertyId = property?.id;
  const variables = {
    propertyId,
    category,
  };

  const { data, loading, error, refetch } = useGetSubmissionsForPropertyQuery({
    variables,
    skip: loadingPropertyId || !propertyId,
    fetchPolicy: "cache-and-network",
  });

  if (loading || loadingPropertyId) {
    return (
      <Layout title={title}>
        <em>Loading...</em>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout title={title}>
        <span>Error loading {lowerCase(title)}</span>
      </Layout>
    );
  }

  if (!propertyId || !data?.getProperty) {
    return (
      <MissingProperty
        title={`No ${title.toLowerCase()} available`}
        address={address}
        latitude={latitude}
        longitude={longitude}
      />
    );
  }

  const currentUser = user || admin;
  const canCreateSubmission = canCreateInternalSubmission({
    role: currentUser?.role.name,
  });

  const actionButton = (
    <CreateSubmissionButton
      disabled={!canCreateSubmission}
      objectDisplay={getObjectDisplay({
        property: { fullAddress: address, latitude, longitude },
      })}
      onSubmit={submissionTypeId =>
        createSubmissionRedirect({
          history,
          submissionTypeId,
          objectId: propertyId,
          objectType: OBJECT_TYPE.PROPERTY,
        })
      }
      submissionTypeFilter={submissionType =>
        submissionType.category === category &&
        submissionType.intakeSource === SubmissionIntakeSource.INTERNAL &&
        submissionType.attachments
          .map(attachment => attachment.type)
          .includes(OBJECT_TYPE.PROPERTY)
      }
    />
  );

  const submissions = data.getProperty.submissions;
  if (!submissions.length) {
    return (
      <React.Fragment>
        <Layout title={title} actionButton={actionButton}>
          <EmptyState
            icon="add-file"
            title={`No ${lowerCase(title)} for this property`}
            body={`Add ${lowerCase(title)} to get started.`}
          />
        </Layout>
      </React.Fragment>
    );
  }

  return (
    <Layout title={title} actionButton={actionButton}>
      {submissions.map(submission => (
        <ListItem
          key={submission.id}
          submission={submission}
          onUpdate={refetch}
        />
      ))}
    </Layout>
  );
};
