import React from "react";

import {
  AddFileIllustration,
  AddRasterIllustration,
  EmptyStateIllustration,
  ErrorAlertIllustration,
  ErrorPuzzleIllustration,
  ErrorQuestionMarkIllustration,
  MissingFileIllustration,
  MissingIncompleteIllustration,
  MissingLocationIllustration,
  NoActivityBeachIllustration,
  NoActivityHotAirBalloonIllustration,
  NoActivityInboxIllustration,
} from "../../images/illustrations";

import { toSentenceCase } from "common/utils/strings";

// The imported SVGs as paths as strings to the images themselves.
// This typing is so that we are more explicit about this.
type SVGPath = string;

export const ILLUSTRATION_NAMES = [
  "add-file",
  "add-raster",
  "empty-state",
  "error-alert",
  "error-puzzle",
  "error-question-mark",
  "missing-file",
  "missing-incomplete",
  "missing-location",
  "no-activity-beach",
  "no-activity-hot-air-balloon",
  "no-activity-inbox",
] as const;

export const ILLUSTRATION_MAP = {
  "add-file": AddFileIllustration,
  "add-raster": AddRasterIllustration,
  // Deprecated. Included here for compatibility. Please use another illustration.
  "empty-state": EmptyStateIllustration,
  "error-alert": ErrorAlertIllustration,
  "error-puzzle": ErrorPuzzleIllustration,
  "error-question-mark": ErrorQuestionMarkIllustration,
  "missing-file": MissingFileIllustration,
  "missing-incomplete": MissingIncompleteIllustration,
  "missing-location": MissingLocationIllustration,
  "no-activity-beach": NoActivityBeachIllustration,
  "no-activity-hot-air-balloon": NoActivityHotAirBalloonIllustration,
  "no-activity-inbox": NoActivityInboxIllustration,
} satisfies Record<typeof ILLUSTRATION_NAMES[number], SVGPath>;

export type IllustrationName = keyof typeof ILLUSTRATION_MAP;

const Illustration = ({
  alt,
  name,
  style,
}: {
  alt?: string;
  name: IllustrationName;
  style?: React.CSSProperties;
}) => {
  const src = ILLUSTRATION_MAP[name];

  return <img src={src} alt={alt ?? toSentenceCase(name)} style={style} />;
};

export default Illustration;
