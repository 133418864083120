import React, {
  ComponentProps,
  ForwardedRef,
  MutableRefObject,
  useRef,
} from "react";
import MapGL from "react-map-gl";

import { getLoginPageURL } from "../../../utils/session";
import { Viewport } from "../utils/viewportHook";
import useLayeredMapHook from "./layeredMapHook";

import type {
  LayerConfigs,
  Account,
  CurriedHookBuilder,
  LayerHookBuilder,
  ParentLayerNames,
  MapRef,
} from "./types";
import TooltipComponent from "./Tooltip";
import CustomMapController from "./CustomMapController";
import { Search, SearchResultProps } from "../../Search";

import markerHook from "./markerLayer";
import accountBoundaryHook from "./accountBoundaryLayer";
import propertyHook from "./propertyLayer";
import customMapHook from "./customMapLayer";
import firmHook from "./firmLayer";
import cbrsHook from "./cbrsLayer";
import contourHook from "./contourLayer";
import parcelHook from "./parcelLayer";
import rasterHook from "./rasterLayer";

import { SearchContainer } from "./__styles__/LayeredMap";
import { measureHook } from "./measureLayer";
import savedViewsHook from "./savedViewsLayer";
import savedViewsClusterHook from "./savedViewsClusterLayer";
import deviceLocationsHook from "./userDeviceLocationsLayer";
import clickObjectMenuHook from "./clickObjectMenuLayer";

const layerHookBuilders = {
  parcels: parcelHook,
  marker: markerHook,
  accountBoundary: accountBoundaryHook,
  properties: propertyHook,
  firms: firmHook,
  customMaps: customMapHook,
  savedViews: savedViewsHook,
  savedViewsCluster: savedViewsClusterHook,
  cbrs: cbrsHook,
  contour: contourHook,
  measure: measureHook,
  raster: rasterHook,
  deviceLocations: deviceLocationsHook,
  clickObjectMenu: clickObjectMenuHook,
};

type Props = {
  layers: LayerConfigs;
  onSearchResult?: (result: SearchResultProps) => void;
  baseMapStyle: Record<string, any>;
  height: string;
  width: string;
  minZoom?: number;
  account: Account;
  viewport: Viewport;
  setViewport: (viewport: Viewport, trigger?: any) => void;
  disableInteraction?: boolean;
  addressOnly?: boolean;
  halfMap?: boolean;
} & ComponentProps<typeof MapGL>;

export default React.forwardRef((props: Props, ref: ForwardedRef<MapRef>) => {
  const controller = new CustomMapController();
  const mapRef = (ref || useRef(null)) as MutableRefObject<MapRef>;

  const hookBuilders = Object.entries(props.layers).map(([layerId, config]) => {
    const layerHookBuilder = layerHookBuilders[
      layerId as ParentLayerNames
    ] as LayerHookBuilder<typeof config>;
    const builder: CurriedHookBuilder = callback =>
      callback(layerHookBuilder, config);
    return builder;
  });

  const {
    onClick,
    onHover,
    onLoad,
    onFocusLost,
    tooltip,
    layers,
    cursor,
    interactiveLayerIds,
    onLoadError,
  } = useLayeredMapHook({
    account: props.account,
    hookBuilders,
    map: mapRef,
  });

  return (
    <>
      <MapGL
        ref={mapRef}
        getCursor={() => cursor}
        mapboxApiAccessToken={window.env.MAPBOX_ACCESS_TOKEN}
        {...props.viewport}
        {...props}
        interactiveLayerIds={interactiveLayerIds}
        attributionControl={false}
        reuseMaps={false}
        onClick={onClick}
        onHover={onHover}
        onMouseOut={onFocusLost}
        controller={controller}
        onLoad={onLoad}
        mapStyle={props.baseMapStyle}
        onError={(e: any) => {
          if (e.error.status === 401) {
            window.location.href = getLoginPageURL(location);
          } else if (e.error.status === 503) {
            onLoadError(e);
          }
        }}
        onViewportChange={
          !props.disableInteraction ? props.setViewport : () => {}
        }
      >
        {layers}
        {props.children}
        <TooltipComponent tooltip={tooltip} />
      </MapGL>
      {props.onSearchResult && (
        <SearchContainer halfMap={props.halfMap ?? false}>
          <Search
            handleResultClick={props.onSearchResult}
            addressOnly={props.addressOnly}
          />
        </SearchContainer>
      )}
    </>
  );
});

export * from "./types";
