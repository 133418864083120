import React, { useContext, useEffect } from "react";

import { checkForSavedViewsBreakingChanges } from "common/utils/submissions";
import { FormProvider, useForm } from "react-hook-form";
import FullPageFormLayout from "../../../../Common/FullPageFormLayout";
import {
  StyledButton,
  SubmissionsBuilderButtonsWrapper,
  SubmissionsBuilderWrapper,
} from "./__styles__/SubmissionsBuilder";
import { MainColumn } from "./MainColumn/MainColumn";
import { SidebarColumn } from "./SidebarColumn/SidebarColumn";
import FieldSettingsView from "./SidebarColumn/FieldSettingsView";
import { SubmissionsBuilderContextInstance } from "./context";
import { SUBMISSIONS_BUILDER_SETTINGS_MODE } from "./reducer";
import { SubmissionsBuilderFormDataStructure } from "./types";
import useSubmissionsBuilderContextManager from "./useSubmissionsBuilderContextManager";
import { useHistory, useLocation, useParams } from "react-router";
import {
  GetSubmissionTypeQuery,
  useCreateSubmissionTypeMutation,
  useGetSubmissionsSavedViewsQuery,
  useGetSubmissionTypeQuery,
  useUpdateSubmissionTypeMutation,
} from "../../../../../generated/graphql";
import { buildFormStructure } from "common/services/formBuilderService";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { isEmpty, omit } from "lodash";
import {
  CreateSubmissionTypeFormStructure,
  useCreateSubmissionTypeModal,
} from "./createSubmissionTypeModal";
import { ApolloError } from "@apollo/client";
import { buildLink } from "common/routing";
import { getUserErrors } from "common-client/utils/apollo";
import resolver from "./formResolver";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { captureException } from "@sentry/browser";
import { Button } from "../../../../Common/Button";
import {
  buildNotificationEmailsArray,
  generateDefaultValues,
  getValidPostambleForIntakeSource,
  getValidPreambleForIntakeSource,
} from "./utils";
import { INTAKE_SOURCE, OBJECT_TYPE } from "common/constants";
import SupportedMobileVersionsContainer from "./SupportedMobileVersionsContainer";
import { DEFAULT_POSTAMBLE, DEFAULT_PREAMBLE } from "./constants";
import { getDocumentFieldsFromUISchema } from "common/services/submissionHelpers";

const SubmissionsBuilder = ({
  existingSubmissionType,
}: {
  existingSubmissionType?: GetSubmissionTypeQuery["submissionType"];
}) => {
  const history = useHistory();
  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const { account } = useContext(AuthContext);
  const { data: submissionsSavedViewsData } = useGetSubmissionsSavedViewsQuery({
    variables: { accountId: account!.id },
    skip: !existingSubmissionType,
    fetchPolicy: "cache-and-network",
  });

  const submissionsSavedViews =
    submissionsSavedViewsData?.account?.submissionsSavedViews;

  const location = useLocation<undefined | { prevLocation?: string }>();
  const prevLocation =
    location.state?.prevLocation ?? "/settings/account/records";

  const defaultValues = generateDefaultValues({ existingSubmissionType });

  const context = useSubmissionsBuilderContextManager();
  const { settingsMode } = context;

  const formMethods = useForm<SubmissionsBuilderFormDataStructure>({
    // @ts-ignore -- until we get all other input types finished
    defaultValues,
    context,
    resolver,
    mode: "onChange",
  });

  const { isValid } = formMethods.formState;
  const { handleSubmit, setValue, watch } = formMethods;

  const onCompleted = (verb: "created" | "updated") => {
    addSuccessToast(`Submission type ${verb}`);
    history.push(buildLink("records"));
  };

  const onError = (error: ApolloError) => {
    const userErrors = getUserErrors(error);

    if (userErrors) {
      addErrorToast(userErrors);
    } else {
      addErrorToast("Could not save submission type. Please try again.");
    }
  };

  const [createSubmissionType] = useCreateSubmissionTypeMutation({
    onCompleted: () => onCompleted("created"),
    onError,
  });

  const [updateSubmissionType] = useUpdateSubmissionTypeMutation({
    onCompleted: () => onCompleted("updated"),
    onError,
  });

  const [showCreateSubmissionTypeModal] = useCreateSubmissionTypeModal({
    context,
    onSubmit: ({
      name,
      category,
      intakeSource,
      attachments,
    }: CreateSubmissionTypeFormStructure) => {
      setValue("name", name);
      setValue("category", category);
      setValue("intakeSource", intakeSource);
      setValue("attachments", attachments);
      if (intakeSource === INTAKE_SOURCE.INTERNAL) {
        setValue("preamble", null);
        setValue("postamble", null);
        setValue("notificationEmails", null);
        setValue("successMessage", `Your ${name} has been saved`);
      } else {
        setValue("postamble", DEFAULT_POSTAMBLE);
        setValue("preamble", DEFAULT_PREAMBLE);
        setValue("successMessage", null);
      }
    },
  });

  useEffect(() => {
    if (!existingSubmissionType) {
      showCreateSubmissionTypeModal();
    }
  }, []);

  const formName = watch("name");

  const subtitle = formName ? `${formName} form` : "Add submission type";

  const handleSubmitCallback = async (
    data: SubmissionsBuilderFormDataStructure
  ) => {
    const formStructure = buildFormStructure({ formData: data.inputs! });

    if (!formStructure.isValid) {
      let errorMessage = existingSubmissionType
        ? `formStructure for existing submission type (id=${existingSubmissionType.id})`
        : "formStructure";

      errorMessage += `is invalid. Please address the following errors:\n${formStructure.errors.join(
        "\n"
      )}`;

      throw new Error(errorMessage);
    }

    if (
      data.attachments.some(
        attachment => attachment.type === OBJECT_TYPE.CUSTOM_MAP_GEOMETRY
      ) &&
      getDocumentFieldsFromUISchema({
        schema: formStructure.schema,
        uiSchema: formStructure.uiSchema,
      }).length
    ) {
      addErrorToast(
        "Associating submissions containing document uploads with custom objects is not yet supported."
      );
    } else if (existingSubmissionType) {
      const savedViewBreakingChanges = checkForSavedViewsBreakingChanges({
        submissionTypeId: existingSubmissionType.id,
        submissionTypeName: existingSubmissionType.name,
        previousFormStructure:
          existingSubmissionType.currentVersion.formStructure,
        currentFormStructure: formStructure,
        submissionSavedViews: submissionsSavedViews ?? [],
        category: existingSubmissionType.category,
        onError: error => {
          captureException(error);
        },
      });

      if (isEmpty(savedViewBreakingChanges)) {
        await updateSubmissionType({
          variables: {
            data: {
              ...omit(data, [
                "inputs",
                "modules",
                "postamble",
                "preamble",
                "notificationEmails",
              ]),
              postamble: getValidPostambleForIntakeSource(data),
              preamble: getValidPreambleForIntakeSource(data),
              formStructure: omit(formStructure, ["isValid", "errors"]),
              submissionTypeId: existingSubmissionType.id,
              notificationEmails: buildNotificationEmailsArray(data),
            },
          },
        });
      } else {
        addErrorToast(
          "Updating this record will break currently saved table views. Modify these views before making changes to the record."
        );
      }
    } else {
      await createSubmissionType({
        variables: {
          data: {
            ...omit(data, [
              "inputs",
              "postamble",
              "preamble",
              "notificationEmails",
            ]),
            postamble: getValidPostambleForIntakeSource(data),
            preamble: getValidPreambleForIntakeSource(data),
            formStructure: omit(formStructure, ["isValid", "errors"]),
            notificationEmails: buildNotificationEmailsArray(data),
          },
        },
      });
    }
  };

  const rightContainer = (
    <SubmissionsBuilderButtonsWrapper>
      <Button
        styleVariant={"ghost"}
        onClick={() => {
          history.push(prevLocation);
        }}
      >
        Cancel
      </Button>
      <StyledButton
        styleVariant={"primary"}
        onClick={handleSubmit(handleSubmitCallback)}
        disabled={!isValid}
      >
        Save
      </StyledButton>
    </SubmissionsBuilderButtonsWrapper>
  );

  return (
    <SubmissionsBuilderContextInstance.Provider value={context}>
      <SupportedMobileVersionsContainer>
        <FormProvider {...formMethods}>
          <FullPageFormLayout
            subtitle={subtitle}
            prevLocation={prevLocation}
            rightContainer={rightContainer}
            centered={false}
            width="auto"
          >
            <SubmissionsBuilderWrapper>
              {settingsMode === SUBMISSIONS_BUILDER_SETTINGS_MODE.FIELD ? (
                <FieldSettingsView key={context.selectedField.name} />
              ) : (
                <SidebarColumn />
              )}
              <MainColumn />
            </SubmissionsBuilderWrapper>
          </FullPageFormLayout>
        </FormProvider>
      </SupportedMobileVersionsContainer>
    </SubmissionsBuilderContextInstance.Provider>
  );
};

const SubmissionBuilderWrapper = () => {
  const { submissionTypeId } = useParams<{ submissionTypeId?: string }>();

  const { data, error, loading } = useGetSubmissionTypeQuery({
    fetchPolicy: "cache-and-network",
    variables: { submissionTypeId: submissionTypeId! },
    skip: !submissionTypeId,
  });

  if (error || loading) {
    // data-testid is here since we don't have a way to reliably test this
    // resulting <div /> component in isolation
    return <div data-testid={error ? "Error" : "Loading"}></div>;
  }

  return <SubmissionsBuilder existingSubmissionType={data?.submissionType} />;
};

export default SubmissionBuilderWrapper;
