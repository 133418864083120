import React, { useContext } from "react";
import { Button } from "../Common/Button";
import { AuthContext } from "../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";

import { track } from "../../utils/tracking";
import { DocumentUploadsTable } from "./FilesTable";
import ActionButton from "./ActionButton";
import { useUploadDocumentsFormModal } from "./ElevationCertificates/hooks";
import ProcessingNotice from "./ElevationCertificates/ProcessingNotice";
import ExportDataButton from "../Exports/ExportDataButton";
import { ActionCellContainer } from "../Common/__styles__/ActionCell";
import { QueryFileTableResult } from "./__queries__/table";
import { CRS_310_REPORT, EC_REPORT } from "../Exports/constants";

const ActionCellDef = ({
  original,
  onUpdate,
}: {
  original: QueryFileTableResult;
  onUpdate: () => {};
}) => {
  return (
    <ActionCellContainer>
      <ActionButton
        isDetailView={false}
        onUpdate={onUpdate}
        documentUpload={original}
      />
    </ActionCellContainer>
  );
};

export const ListDocumentUploads = () => {
  const { authorized } = useContext(AuthContext);
  const [showUploadModal] = useUploadDocumentsFormModal();

  const canAddCertificates = authorized({
    resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
    permission: "create",
  });

  const onClickUpload = () => {
    track("Clicked upload from EC List");
    showUploadModal();
  };

  const actions = (
    <>
      <ExportDataButton initialReportTypes={[EC_REPORT, CRS_310_REPORT]} />
      <Button
        onClick={onClickUpload}
        size="small"
        styleVariant="primary"
        disabled={!canAddCertificates}
        aria-label="Upload Elevation Certificates"
        leftIconName="plus"
      >
        Upload Elevation Certificates
      </Button>
    </>
  );

  return (
    <React.Fragment>
      <ProcessingNotice />
      <DocumentUploadsTable tableActions={actions} actionCell={ActionCellDef} />
    </React.Fragment>
  );
};
