import React, { useContext } from "react";
import useNavigationTab from "../../hooks/useNavigationTabs";
import { AddressPanelTabProps } from ".";
import Improvements from "./Improvements";
import {
  Section,
  TabContainer,
  Title,
  Wrapper,
} from "./__styles__/AddressPanel";
import { AuthContext } from "../Authorization/AuthContext";
import { entries, map, pick, upperFirst } from "lodash";
import TabGroup from "../Common/TabGroup";
import Logs from "./Logs";
import Submissions from "./Submissions";
import { RECORDS_TAB_NAME } from "common/routing";
import { Inspections } from "./Inspections";
import { FlexRow } from "../Common/__styles__/Layout";
import { SUBMISSION_CATEGORY } from "common/constants";
import { SUBMISSION_CATEGORY_TO_TAB } from "../Submissions/utils";

export const Layout = ({
  actionButton,
  children,
  title,
}: {
  actionButton?: React.ReactNode;
  children: React.ReactNode;
  title: string;
}) => (
  <div>
    <FlexRow style={{ justifyContent: "space-between" }}>
      <Title>
        <span>{title}</span>
      </Title>
      {actionButton}
    </FlexRow>
    {children}
  </div>
);

const OPTIONAL_TABS = entries(
  pick(
    SUBMISSION_CATEGORY_TO_TAB,
    SUBMISSION_CATEGORY.PERMITTING,
    SUBMISSION_CATEGORY.GRANTS,
    SUBMISSION_CATEGORY.PAYMENTS,
    SUBMISSION_CATEGORY.MAILINGS
  )
).map(([category, recordTabName]) => ({
  recordTabName,
  category: category as SUBMISSION_CATEGORY,
  title:
    category === SUBMISSION_CATEGORY.PERMITTING
      ? "Permits"
      : upperFirst(recordTabName),
}));

export const Records = (props: Omit<AddressPanelTabProps, "onClose">) => {
  const { account } = useContext(AuthContext);
  const categories = new Set(
    account?.submissionTypes.map(type => type.category)
  );

  let tabs = [
    { value: RECORDS_TAB_NAME.IMPROVEMENTS, label: "SI/SD" },
    { value: RECORDS_TAB_NAME.LOGS, label: "Logs" },
    { value: RECORDS_TAB_NAME.INSPECTIONS, label: "Inspections" },
  ];

  tabs = tabs.concat(
    ...OPTIONAL_TABS.filter(({ category }) =>
      categories.has(category as SUBMISSION_CATEGORY)
    ).map(({ recordTabName, title }) => ({
      value: recordTabName,
      label: title,
    }))
  );

  const defaultTab = tabs[0]!;

  const [tab, setTab] = useNavigationTab({
    defaultTab: defaultTab.value,
    allowedTabs: map(tabs, "value"),
    queryParamName: "recordTab",
  });

  const currentOptionalTab = OPTIONAL_TABS.find(
    ({ category, recordTabName }) =>
      category !== SUBMISSION_CATEGORY.INSPECTIONS && tab === recordTabName
  );

  return (
    <>
      <TabContainer style={{ marginTop: "8px" }}>
        <TabGroup currentTab={tab} setTab={setTab} tabs={tabs} />
      </TabContainer>

      <Wrapper>
        <Section>
          {tab === RECORDS_TAB_NAME.IMPROVEMENTS && <Improvements {...props} />}
          {tab === RECORDS_TAB_NAME.LOGS && <Logs {...props} />}
          {tab === RECORDS_TAB_NAME.INSPECTIONS && <Inspections {...props} />}
          {currentOptionalTab && (
            <Submissions
              {...props}
              category={currentOptionalTab.category}
              title={currentOptionalTab.title}
            />
          )}
        </Section>
      </Wrapper>
    </>
  );
};
