import React, { useContext } from "react";
import { buildLink } from "common/routing";
import {
  AccountDocumentTypeName,
  SubmissionContent,
  ViewRecordLink,
} from "./ElevationCertificates/__styles__/DetailViewContentHeader";
import { GetDocumentUploadQuery } from "../../generated/graphql";
import { StyleVariant, Tag } from "../Common/Tag";
import { AuthContext } from "../Authorization/AuthContext";

export type SubmissionType = NonNullable<
  GetDocumentUploadQuery["documentUpload"]
>["submission"];

export type DetailViewContentHeaderProps = {
  accountDocumentTypeName: string;
  submission?: SubmissionType;
};

export default ({
  accountDocumentTypeName,
  submission,
}: DetailViewContentHeaderProps) => {
  const { isGuest } = useContext(AuthContext);

  return (
    <div data-testid="details-content-header">
      <AccountDocumentTypeName>
        {accountDocumentTypeName}
      </AccountDocumentTypeName>
      {submission && !isGuest && (
        <SubmissionContent>
          <ViewRecordLink
            data-testid="view-record-link"
            to={{
              pathname: buildLink("viewSubmission", {
                submissionId: submission.id,
              }),
            }}
            target="_blank"
          >
            Related record
          </ViewRecordLink>
          {submission.tags
            .filter(tag => tag.uiSchemaPath === "submissionInfo.reviewStatus")
            .map(({ text, display }, index) => {
              return (
                <Tag
                  styleVariant={display as StyleVariant}
                  key={`${text}-${index}`}
                >
                  {text}
                </Tag>
              );
            })}
        </SubmissionContent>
      )}
    </div>
  );
};
