import React from "react";
import { useModal } from "react-modal-hook";

import Modal from "../../../Common/Modal";
import { Property } from "./types";
import { useUpdatePropertyFirmMutation } from "../../../../generated/graphql";

import { OptionsWrapper, InputRow } from "../../../Common/__styles__/Modal";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import { pickZones } from "common-client/utils/firms";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { isAOZone } from "common/services/firmHelpers";
import { FIRM_UPDATE_REFECTH_QUERIES } from "./FIRMEditModal";
import { MultiStepModal } from "../../../Common/CommonModal";
import SelectionCardGroup, {
  SelectionCardOption,
} from "../../../Inputs/SelectionCardGroup";
import { useForm } from "react-hook-form";
import { isNotNil } from "common/utils/tools";
import { ReactHookFormTextInput } from "../../../Inputs/Text";
import { arrayHasAtLeastOneItem } from "common/utils/arrays";
import { Body } from "../../../Common/Typography";
import {
  BASE_FLOOD_MODES,
  getCleanInputValue,
  hasFloodZone,
} from "./BaseFloodEditModal";
import { getFloodzoneExplanatoryText } from "common-client/utils/floodzones";
import { FLOOD_ZONES } from "common/constants";
import { uniq } from "lodash";

export interface FloodzoneEditFormProps {
  property: NonNullable<Property>;
  firm?: FIRMWithWarnings;
  openApproximateBfeTool?: () => void;
  onCancel: () => void;
  onSave: () => void;
  refetchQueries?: string[];
}

export const useFloodzoneEditModal = ({
  onSave,
  onCancel,
  ...rest
}: FloodzoneEditFormProps) => {
  const [showFloodzoneEditModal, hideFloodzoneEditModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideFloodzoneEditModal();
          onCancel();
        }}
      >
        <FloodzoneEditForm
          onCancel={() => {
            hideFloodzoneEditModal();
            onCancel();
          }}
          onSave={() => {
            hideFloodzoneEditModal();
            onSave();
          }}
          {...rest}
        />
      </Modal>
    ),
    [rest, onSave]
  );

  return [showFloodzoneEditModal, hideFloodzoneEditModal] as const;
};

const FloodzoneEditForm = ({
  property,
  firm,
  onCancel,
  onSave,
  openApproximateBfeTool,
  refetchQueries = FIRM_UPDATE_REFECTH_QUERIES,
}: FloodzoneEditFormProps) => {
  if (!firm) return null;

  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const propertyId = property.id;

  const [updateFIRM, { loading }] = useUpdatePropertyFirmMutation({
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      onSave();
      addSuccessToast(`${firm.name} data successfully updated`);
    },
    onError: () => {
      addErrorToast(
        `There was an issue updating the ${firm.name} data. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  type FormState = {
    floodzone: string;
    stringStaticBFE: Maybe<string>;
    stringDepth: Maybe<string>;
  };

  const floodzoneOptions = uniq(
    firm.zones.map(zone => zone.floodzone).filter(isNotNil)
  ).map((floodzone, index) => {
    return {
      id: `${floodzone}-${index}`,
      value: floodzone,
      title: `Zone ${floodzone}`,
      description: getFloodzoneExplanatoryText(floodzone),
      iconName: "land-plot",
    } satisfies SelectionCardOption;
  });

  const { handleSubmit, register, setValue, watch } = useForm<FormState>({
    defaultValues: {
      floodzone: firm.floodzone,
      stringDepth: firm.stringDepth,
      stringStaticBFE: firm.stringStaticBFE,
    },
  });

  const handleFloodzoneChange = (value: string) => {
    setValue("floodzone", value);
    setValue("stringDepth", null);
    setValue("stringStaticBFE", null);
  };

  const handleOptionChange = (
    key: "stringStaticBFE" | "stringDepth" | "floodzone",
    value: string
  ) => {
    setValue(key, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onSubmit = async (formData: FormState) => {
    const { floodzone, stringDepth, stringStaticBFE } = formData;

    const relevantZoneOptions = pickZones(firm.zones).filter(
      zone => zone.floodzone === floodzone
    );

    const formattedBFE = getCleanInputValue(
      stringStaticBFE,
      relevantZoneOptions.map(zone => zone.stringStaticBFE!)
    );

    const formattedBFD = getCleanInputValue(
      stringDepth,
      relevantZoneOptions.map(zone => zone.stringDepth!)
    );

    await updateFIRM({
      variables: {
        propertyId,
        firmId: firm.id,
        data: {
          floodzone: floodzone as FLOOD_ZONES,
          stringStaticBFE: formattedBFE,
          stringDepth: formattedBFD,
          isApproximateBfe: false,
        },
      },
    });
  };

  const FloodZoneStep = {
    title: `Flood zone`,
    subtitle: `Select property zone for the ${firm.name}`,
    overflows: false,
    content: (
      <OptionsWrapper>
        <SelectionCardGroup
          onChange={handleFloodzoneChange}
          options={floodzoneOptions}
          value={watch("floodzone")}
          data-orientation="vertical"
          spacingStyle="compact"
          borderStyle="ghost"
        />
      </OptionsWrapper>
    ),
  };

  const floodzone = watch("floodzone") as FLOOD_ZONES;
  const secondStep = isAOZone(floodzone) ? "depth" : "elevation";

  let {
    title,
    getSubtitle,
    textInputLabel,
    generateOptions,
    generateSecondaryActions,
    formDataKey,
  } = BASE_FLOOD_MODES[secondStep];

  if (!formDataKey) return null;

  const relevantZones = pickZones(firm.zones)
    .filter(zone => hasFloodZone(zone))
    .filter(zone => zone.floodzone === floodzone);

  const options = generateOptions(relevantZones);

  const subtitle = getSubtitle(firm.name);
  const secondaryButtons =
    generateSecondaryActions?.(openApproximateBfeTool) ?? [];

  const SecondStep = {
    title,
    subtitle,
    secondaryButtons,
    overflows: false,
    content: (
      <>
        {textInputLabel && (
          <InputRow>
            <ReactHookFormTextInput
              {...register(formDataKey)}
              label={textInputLabel}
              tabIndex={0}
              type="number"
              step="0.1"
            />
          </InputRow>
        )}
        {arrayHasAtLeastOneItem(options) && (
          <OptionsWrapper>
            <Body size="small" type="emphasis" color={"contentPlaceholder"}>
              MAP DATA FOR SELECTED ZONE
            </Body>
            <SelectionCardGroup
              onChange={value => handleOptionChange(formDataKey, value)}
              options={options}
              value={watch(formDataKey) ?? undefined}
              data-orientation="vertical"
              spacingStyle="compact"
              borderStyle="ghost"
            />
          </OptionsWrapper>
        )}
      </>
    ),
  };

  return MultiStepModal({
    onCancel,
    size: "medium",
    height: "short",
    onSubmit: handleSubmit(onSubmit),
    loading,
    showNextButton: true,
    steps: [FloodZoneStep, SecondStep],
  });
};

export default FloodzoneEditForm;
