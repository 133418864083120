import React, { useEffect } from "react";

import { EnumOptionsType, WidgetProps as RJSFWidgetProps } from "@rjsf/utils";

import { Datepicker, Select, Textarea, Text, Checkbox, Label } from "../Inputs";

import { Input } from "./__styles__/Inputs";
import { castEmptyStringToNull, getTooltip, useDefaultedValue } from "./utils";
import { isNil, merge } from "lodash";
import { Fieldset } from "../Inputs/__styles__/Wrapper";
import { Legend } from "../Inputs/__styles__/Label";

export type WidgetProps = RJSFWidgetProps & { isSubmissionBuilder?: boolean };

export const CustomSelect = (props: WidgetProps) => {
  const { value } = useDefaultedValue(props);

  const tooltip = getTooltip(props);
  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Select
        name={props.id}
        label={props.label}
        required={props.required && !props.disabled}
        value={value}
        onChange={value => props.onChange(isNil(value) ? undefined : value)}
        disabled={props.disabled}
        options={props.options.enumOptions!.map((option: EnumOptionsType) => {
          return { label: option.label, value: option.value };
        })}
        placeholder={props.placeholder}
        helperText={props.uiSchema?.["ui:helperText"]}
        error={props.rawErrors?.join(", ")}
        tooltip={tooltip}
        css={!!tooltip ? { width: "100%" } : {}}
        isClearable={true}
      />
    </Input>
  );
};

export const CustomTextarea = (props: WidgetProps) => {
  const { value } = useDefaultedValue({
    ...props,
    // in case someone picks a non-string default
    onChange: value => props.onChange(value ? value.toString() : value),
  });

  const tooltip = getTooltip(props);
  const helperText = props.uiSchema?.["ui:helperText"];

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Textarea
        name={props.id}
        label={props.label}
        required={props.required && !props.disabled}
        value={value}
        onChange={value => {
          props.onChange(
            castEmptyStringToNull({
              value,
              required: props.required ?? false,
            })
          );
        }}
        disabled={props.disabled}
        placeholder={props.placeholder}
        helperText={helperText}
        error={props.rawErrors?.join(", ")}
        labelSize={props.uiSchema?.["ui:labelSize"]}
        tooltip={tooltip}
      />
    </Input>
  );
};

export const CustomText = (props: WidgetProps) => {
  const { value } = useDefaultedValue({
    ...props,
    // in case someone picks a non-string default
    onChange: value => props.onChange(value ? value.toString() : value),
  });

  const tooltip = getTooltip(props);
  const helperText = props.uiSchema?.["ui:helperText"];

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Text
        name={props.id}
        label={props.label}
        required={props.required && !props.disabled}
        value={value}
        onChange={value =>
          props.onChange(
            castEmptyStringToNull({
              value,
              required: props.required ?? false,
            })
          )
        }
        disabled={props.disabled}
        placeholder={props.placeholder}
        helperText={helperText}
        error={props.rawErrors?.join(", ")}
        labelSize={props.uiSchema?.["ui:labelSize"]}
        tooltip={tooltip}
      />
    </Input>
  );
};

export const CustomDatePicker = (props: WidgetProps) => {
  const defaultToToday = props.uiSchema?.["ui:defaultToToday"];
  const value = useDefaultedValue({
    ...props,
    uiSchema: merge(
      { ...props.uiSchema },
      defaultToToday ? { "ui:defaultValuePath": "date.today" } : {}
    ),
  }).value;

  const tooltip = getTooltip(props);

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Datepicker
        name={props.id}
        label={props.label}
        required={props.required && !props.disabled}
        value={value}
        onChange={value =>
          value ? props.onChange(value) : props.onChange(null)
        }
        disabled={props.disabled}
        helperText={props.uiSchema?.["ui:helperText"]}
        error={props.rawErrors?.join(", ")}
        placeholderText={props.placeholder}
        tooltip={tooltip}
      />
    </Input>
  );
};

export const DamageDegree = (props: WidgetProps) => {
  return <CustomSelect {...props} />;
};

export const CustomCheckbox = (props: WidgetProps) => {
  const { value } = useDefaultedValue(props);

  // It looked like the initial value wasn't displaying correctly,
  // using this useEffect cleaned it up
  useEffect(() => {
    props.onChange(value);
  }, []);

  const tooltip = getTooltip(props);

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Fieldset hasTooltip={!!tooltip}>
        {/* 
          If you want to use functionality Wrapper provides, such as errors or tooltips,
          you will need to update the component to optionally use Legend instead of Label.
          Legend is semantically correct for checkboxes because it isn't associated with the input itself
        */}
        {/* Have to wrap the legend in a div to force it to inline with the tooltip */}
        <div>
          <Legend>{props.label}</Legend>
        </div>
        {tooltip}
        <div style={{ display: "flex", gap: 8, width: "100%" }}>
          <Checkbox
            name={props.id}
            value={value}
            onChange={props.onChange}
            disabled={props.disabled}
          />
          <Label
            text={props.uiSchema?.["ui:checkboxText"]}
            required={props.required && !props.disabled}
            style={{ marginBottom: 0 }}
            htmlFor={props.id}
          />
        </div>
      </Fieldset>
    </Input>
  );
};
