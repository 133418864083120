import React from "react";
import ActionButton from "../../DocumentUploads/ActionButton";

import {
  AccountDocumentType,
  CertificateUploadStatus,
} from "../../../generated/graphql";
import { ViewDocumentLink } from "./ViewDocumentLink";
import DocumentUploadIcon from "./DocumentUploadIcon";
import { MIME_TYPE, UserFriendlyCertificateStatus } from "common/constants";
import { buildLink } from "common/routing";
import {
  Row,
  TextContainer,
  Subtitle,
  Bullet,
  TitleContainer,
  ViewRecordContainer,
} from "./__styles__/ListItem";
import { StyledLink } from "../Activity/__styles__/Message";
import {
  documentUploadDisabled,
  formatDocumentUploadName,
} from "common-client/utils/documentUploads";
import { DynamicOverflowWrapper } from "../../Common/DynamicOverflowWrapper";
import { Body } from "../../Common/Typography";

export interface ListItemProps {
  documentUploadId: string;
  documentUploadMimeType: MIME_TYPE;
  elevationCertificateId?: string;
  formData?: Record<string, string>;
  createdAt: string;
  issuedAt: string;
  hiddenFromPublic: boolean;
  accountDocumentType: Omit<AccountDocumentType, "hiddenFromPublicByDefault">;
  filename: string;
  pathname?: string;
  search?: string;
  status?: Maybe<CertificateUploadStatus>;
  submission?: Maybe<{
    id: string;
    submissionTypeVersion: { submissionType: { name: string } };
  }>;
  isDeletable: boolean;
  propertyId?: Maybe<string>;
  onUpdate?: () => void;
}

export default ({
  documentUploadId,
  documentUploadMimeType,
  elevationCertificateId,
  formData,
  createdAt,
  issuedAt,
  hiddenFromPublic,
  accountDocumentType,
  filename,
  pathname,
  search,
  status,
  submission,
  onUpdate,
  propertyId,
  isDeletable,
}: ListItemProps) => {
  const prevLocation = `${pathname}${search}`;

  const cancelled = status === CertificateUploadStatus.CANCELED;
  const disabled = documentUploadDisabled({
    cancelled,
    status: status || null,
  });

  const linkProps = {
    documentUploadId,
    elevationCertificateId,
    accountDocumentType,
    prevLocation,
    disabled,
  };

  const property = propertyId ? { id: propertyId } : null;

  const documentUpload = {
    id: documentUploadId,
    issuedAt,
    submission,
    accountDocumentType,
    originalFilename: filename,
    property,
    hiddenFromPublic,
    isDeletable,
  };

  const documentUploadTitle = formatDocumentUploadName({
    isSubmission: !!formData,
    createdAt,
    issuedAt,
    accountDocumentTypeName: accountDocumentType.name,
  });

  let fileNameText = filename;
  if (disabled || cancelled) {
    fileNameText += ` - ${UserFriendlyCertificateStatus[status!]}`;
  }

  return (
    <Row>
      <TitleContainer>
        <DocumentUploadIcon documentUploadMimeType={documentUploadMimeType} />
        <TextContainer>
          <Body
            size="default"
            type="emphasis"
            color={disabled ? "contentDisabled" : "contentPrimary"}
          >
            {documentUploadTitle}
          </Body>
          <Subtitle>
            <DynamicOverflowWrapper
              Link={ViewDocumentLink}
              linkProps={linkProps}
              text={fileNameText}
            />

            {/* Because we are using flex, I need the bullet and the submission link to be siblings with the document name */}
            {!!submission && <Bullet>&#9679;</Bullet>}
            {!!submission && (
              <ViewRecordContainer>
                <StyledLink
                  to={{
                    pathname: buildLink("viewSubmission", {
                      submissionId: submission.id,
                    }),
                    state: { prevLocation },
                  }}
                  data-testid="view-submission-link"
                >
                  View related record
                </StyledLink>
              </ViewRecordContainer>
            )}
          </Subtitle>
        </TextContainer>
      </TitleContainer>
      <ActionButton
        documentUpload={documentUpload}
        disabled={disabled}
        onUpdate={onUpdate}
      />
    </Row>
  );
};
