import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AddressPanelContext } from "../AddressPanelContext";

import { useGetDocumentsForPropertyQuery } from "../../../generated/graphql";
import { track } from "../../../utils/tracking";
import MissingProperty from "../MissingProperty";
import List from "./List";
import ListItem from "./ListItem";
import { canDeleteDocumentUpload } from "common-client/utils/documentUploads";
import { AuthContext } from "../../Authorization/AuthContext";

interface Props extends RouteComponentProps {
  address?: Maybe<string>;
  latitude: number;
  longitude: number;
}

export default withRouter((props: Props) => {
  const { location, address, latitude, longitude } = props;
  const { loading: loadingPropertyId, property } =
    useContext(AddressPanelContext);
  const { authorized } = React.useContext(AuthContext);

  const propertyId = property?.id ?? null;

  const { data, loading, refetch } = useGetDocumentsForPropertyQuery({
    variables: { propertyId: propertyId ?? "", isGuest: false },
    fetchPolicy: "network-only",
    skip: !propertyId,
  });

  const isLoading = loadingPropertyId || loading;
  const documentUploads = data?.property?.documentUploads || [];

  useEffect(() => {
    track("Opened files tab", {
      address: property?.fullAddress,
    });
  }, [property]);

  if (isLoading) return <em>loading...</em>;
  if (!property) {
    return (
      <MissingProperty
        title="No files available"
        address={address}
        latitude={latitude}
        longitude={longitude}
      />
    );
  }

  return (
    <List property={property}>
      {documentUploads.map(du => (
        <ListItem
          key={du.id}
          {...du}
          documentUploadId={du.id}
          isDeletable={canDeleteDocumentUpload({
            documentUpload: du,
            authorized,
          })}
          documentUploadMimeType={du.mimeType}
          elevationCertificateId={du.elevationCertificate?.id}
          issuedAt={du.issuedAt}
          createdAt={du.createdAt}
          filename={du.originalFilename}
          pathname={location.pathname}
          status={du.certificateUpload?.status ?? null}
          search={location.search}
          onUpdate={refetch}
          propertyId={propertyId}
        />
      ))}
    </List>
  );
});
