import React from "react";
import { HeaderContainer, ObjectTypeRow } from "./__styles__/Header";
import { Body, Title } from "../Common/Typography";
import { Icon as LucideIcon } from "../Common/Icons/LucideIcons";

type ObjectInfoHeaderProps = {
  loading: boolean;
  objectType: string;
  objectName: string;
  onClose: () => void;
};

const LOADING_TEXT = "Loading...";

const Header = ({
  loading,
  objectType,
  objectName,
  onClose,
}: ObjectInfoHeaderProps) => {
  return (
    <HeaderContainer>
      {loading ? (
        <div>
          <em>{LOADING_TEXT}.</em>
        </div>
      ) : (
        <>
          <ObjectTypeRow>
            <Body size="default" type="emphasis" color="contentPrimaryDark">
              {objectType}
            </Body>
            <LucideIcon
              iconName="x"
              color="contentSecondaryDark"
              size={18}
              onClick={onClose}
              data-testid="close-icon"
            />
          </ObjectTypeRow>
          <Title size="default" type="semiBold" color="contentPrimaryDark">
            {objectName}
          </Title>
        </>
      )}
    </HeaderContainer>
  );
};

export default Header;
