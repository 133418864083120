import React from "react";

import { ObjectDisplay } from "./__styles__/Submission";
import { Header } from "../Common/__styles__/FullPageFormLayout";
import { SubmissionType } from "../../generated/graphql";

type SubmissionHeaderProps = {
  objectDisplay?: string;
  submissionType: Pick<SubmissionType, "name">;
};

const SubmissionHeader: React.FC<SubmissionHeaderProps> = ({
  objectDisplay,
  submissionType,
  children,
}) => {
  const address = objectDisplay ? (
    <ObjectDisplay>{objectDisplay}</ObjectDisplay>
  ) : null;

  return (
    <Header style={{ marginBottom: "8px" }}>
      <h1>{submissionType.name}</h1>
      {address}
      {children}
    </Header>
  );
};

export default SubmissionHeader;
