import React, { createRef, useContext, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useStatusToasts } from "../../hooks/useStatusToasts";

import {
  SubmissionIntakeSource,
  useCreateSubmissionMutation,
  useGetCustomMapGeometryForSubmissionQuery,
  useGetPropertyForSubmissionQuery,
  useGetSisdRuleDataQuery,
  useGetSubmissionQuery,
  useGetSubmissionTypeQuery,
} from "../../generated/graphql";
import FullPageFormLayout from "../Common/FullPageFormLayout";
import { FormComponent, FormDataType } from "./Form";
import { buildLink } from "common/routing";
import { setTitle } from "../../utils/title";
import { accountTitle as getAccountTitle } from "../Guest/utils";
import SubmissionSuccess from "./SubmissionSuccess";
import { track } from "../../utils/tracking";

import { DescriptionContainer } from "./__styles__/CreateSubmission";
import { AuthContext } from "../Authorization/AuthContext";
import {
  IMPROVEMENT_KINDS,
  OBJECT_TYPE,
  SUBMISSION_TYPE_MODULE,
} from "common/constants";
import SubmissionHeader from "./SubmissionHeader";
import { generateUpsertErrorMessage, getObjectDisplay } from "./utils";
import { isNotNil } from "common/utils/tools";
import { z } from "zod";
import useURLParsing from "../../hooks/useURLParsing";

interface CreateSubmissionParams {
  objectId?: string;
  objectType?: OBJECT_TYPE;
  submissionTypeId: string;
}

export interface CreateSubmissionProps
  extends RouteComponentProps<CreateSubmissionParams> {}

type HistoryLocationState = {
  prevLocation?: string;
  search?: string;
  formData?: any;
  relatedSubmissionId?: string;
};

export const CreateSubmission = ({
  objectId,
  objectType,
  submissionTypeId,
}: CreateSubmissionParams) => {
  const history = useHistory();
  const location = useLocation<Maybe<HistoryLocationState>>();
  const relatedSubmissionId = location.state?.relatedSubmissionId ?? null;
  const [disabled, setDisabled] = useState(false);
  const initialFormData = location.state?.formData;

  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false);
  const accountTitle = getAccountTitle();
  const { user, admin, isGuest, account } = useContext(AuthContext);

  const { data, loading, error } = useGetSubmissionTypeQuery({
    fetchPolicy: "cache-and-network",
    variables: { submissionTypeId, isGuest },
  });

  const { data: propertyData, loading: propertyLoading } =
    useGetPropertyForSubmissionQuery({
      variables: { propertyId: objectId!, isGuest },
      skip: !objectId || objectType !== OBJECT_TYPE.PROPERTY,
    });

  const { data: customMapGeometryData, loading: customMapGeometryLoading } =
    useGetCustomMapGeometryForSubmissionQuery({
      variables: { geometryId: objectId! },
      skip: !objectId || objectType !== OBJECT_TYPE.CUSTOM_MAP_GEOMETRY,
    });

  const { data: relatedSubmissionData, loading: relatedSubmissionLoading } =
    useGetSubmissionQuery({
      variables: { submissionId: relatedSubmissionId! },
      skip: !relatedSubmissionId,
    });

  const submissionTypeModules = account?.submissionTypes.find(
    submissionType => submissionType.id === submissionTypeId
  )?.modules;

  const { data: sisdRuleData, loading: sisdRuleDataLoading } =
    useGetSisdRuleDataQuery({
      variables: { propertyId: objectId! },
      skip:
        !submissionTypeModules?.includes(
          SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
        ) ||
        !objectId ||
        objectType !== OBJECT_TYPE.PROPERTY,
    });

  const submissionType = data?.submissionType;
  const [createSubmission, { loading: createLoading }] =
    useCreateSubmissionMutation({
      onCompleted: data => {
        const submission = data.createSubmission;
        track("Submission created", {
          submissionId: submission.id,
          "Submission Category": submissionType?.category,
          "Submission Type": submissionType?.name,
        });

        if (submissionType?.intakeSource === SubmissionIntakeSource.EXTERNAL) {
          setShowSubmissionSuccess(true);
        }

        if (submissionType?.intakeSource === SubmissionIntakeSource.INTERNAL) {
          if (data.createSubmission.summaries?.length) {
            history.push({
              pathname: buildLink("submissionSummary", {
                submissionId: submission.id,
              }),
              state: { prevLocation },
            });
          } else {
            history.push(prevLocation, { forceRefresh: true });
          }
          addSuccessToast(submissionType.successMessage!);
        }
      },
      onError: error => {
        const message = generateUpsertErrorMessage(error);
        addErrorToast(message);
      },
    });

  if (
    loading ||
    propertyLoading ||
    customMapGeometryLoading ||
    relatedSubmissionLoading ||
    sisdRuleDataLoading ||
    error ||
    !data?.submissionType
  ) {
    return <div />;
  }

  if (data.submissionType.intakeSource === SubmissionIntakeSource.EXTERNAL) {
    setTitle(`Create Submission | ${accountTitle}`);
  }
  const submitFormRef = createRef<HTMLButtonElement>();
  const versionId = data.submissionType.currentVersion.id;

  const baseLocation =
    data.submissionType.intakeSource === SubmissionIntakeSource.EXTERNAL
      ? "guestHome"
      : "map";

  const prevLocation = `${
    location.state?.prevLocation ?? buildLink(baseLocation)
  }${location.state?.search ?? ""}`;

  const handleSubmit = async (formData: FormDataType) => {
    await createSubmission({
      variables: {
        data: {
          formData,
          versionId,
          relatedSubmissionId,
          ...(objectId && objectType ? { objectId, objectType } : {}),
        },
        isPublic: isGuest,
      },
    });
  };

  const handleOnChange = ({
    documentUploadsInProgress,
  }: {
    documentUploadsInProgress: boolean;
  }) => {
    setDisabled(documentUploadsInProgress);
  };

  const customMapGeometry = customMapGeometryData?.customMapGeometry;
  const property = propertyData?.property;
  const parcel = property?.parcel;

  let componentToDisplay: JSX.Element;
  if (showSubmissionSuccess) {
    componentToDisplay = (
      <SubmissionSuccess
        preamble={data.submissionType.preamble!}
        postamble={data.submissionType.postamble!}
      />
    );
  } else {
    componentToDisplay = (
      <FullPageFormLayout
        subtitle={`Add new ${data.submissionType.name}`}
        prevLocation={prevLocation}
        onSave={() => {
          submitFormRef.current!.click();
        }}
        buttonText="Submit"
        buttonDisabled={createLoading || disabled}
        buttonLoading={createLoading}
      >
        <div>
          <SubmissionHeader
            submissionType={data.submissionType}
            objectDisplay={getObjectDisplay({ property, customMapGeometry })}
          >
            {data.submissionType.description && (
              <DescriptionContainer
                markdown={data.submissionType.description}
              />
            )}
          </SubmissionHeader>
          <FormComponent
            submissionType={data.submissionType}
            onSubmit={handleSubmit}
            onChange={handleOnChange}
            submitFormRef={submitFormRef}
            initialFormData={initialFormData}
            relatedSubmissions={[relatedSubmissionData?.submission].filter(
              isNotNil
            )}
            defaultValues={{
              property: {
                sisd: {
                  adjustmentRatio: property?.sisd.rule?.adjustmentRatio,
                  defaultExclude: property?.sisd.rule?.defaultExclude,
                  costType: IMPROVEMENT_KINDS.IMPROVEMENT,
                },
              },
              parcel,
              user: user ?? admin,
            }}
            account={sisdRuleData?.account}
            property={{ FIRMInfo: sisdRuleData?.FIRMInfo }}
          />
        </div>
      </FullPageFormLayout>
    );
  }

  return componentToDisplay;
};

const objectTypeQueryParam = z
  .object({
    objectType: z.nativeEnum(OBJECT_TYPE).optional(),
  })
  .strict();

const CreateSubmissionContainer = ({ match }: CreateSubmissionProps) => {
  const { submissionTypeId, objectId } = match.params;
  const objectType =
    useURLParsing(objectTypeQueryParam)?.objectType ?? OBJECT_TYPE.PROPERTY;

  return (
    <CreateSubmission
      submissionTypeId={submissionTypeId}
      objectId={objectId}
      objectType={objectType}
    />
  );
};

export default CreateSubmissionContainer;
