import React, { useContext, useEffect, useMemo, useRef } from "react";

import CustomMapLayer, {
  CUSTOM_MAPS_SOURCE_ID_PREFIX,
  getInteractiveLayerById,
} from "../layers/customMap";
import {
  EventFeature,
  findRelevantFeatures,
  LayerConfig,
  LayerHook,
  LayerHookBuilder,
  LayerId,
} from "./types";
import { CustomMap, LayerContext, LayerContextType } from "../layers";
import { flatten, uniqBy } from "lodash";
import { captureMessage } from "@sentry/browser";
import { MapEvent } from "react-map-gl";

type CustomMapLayerValue = {
  customMapId?: Maybe<string>;
  geometryId?: Maybe<string>;
};

export type CustomMapLayerConfig = LayerConfig<CustomMapLayerValue> & {
  maps: Array<CustomMap>;
};

type GeometryRef = Pick<EventFeature, "id" | "source" | "sourceLayer">;

type SelectFeatureProps = {
  event: MapEvent;
  allInteractiveLayerIds: LayerId[];
  errorOnOverlap: boolean;
};

const selectFeature = ({
  event,
  allInteractiveLayerIds,
  errorOnOverlap,
}: SelectFeatureProps) => {
  // there could be multiple layers displaying a feature, so we dedupe by id
  const features = uniqBy(
    findRelevantFeatures(event, allInteractiveLayerIds),
    "id"
  );

  if (errorOnOverlap && features.length > 1) {
    // If this triggers, let product know what use-case caused it and we will figure out what behavior should be
    captureMessage(
      `There is an overlap between some of the following isObject custom maps: ${features
        .map(feature =>
          feature.source.replace(CUSTOM_MAPS_SOURCE_ID_PREFIX, "")
        )
        .join(", ")} at the following location: ${event.lngLat[0]}, ${
        event.lngLat[1]
      }. This behavior has not been defined.`
    );
  }

  return features[0];
};

export const getInteractiveLayerIds = (
  maps: CustomMap[],
  isLayerVisible: LayerContextType["isLayerVisible"]
) => {
  return flatten(
    maps
      .filter(map => map.isObject)
      .map(map => map.id)
      .filter(id => isLayerVisible({ group: "customMaps", id }))
      .map(getInteractiveLayerById)
  );
};

const customMapsHook: LayerHookBuilder<CustomMapLayerConfig> = ({
  config,
  mapLoaded,
  helpers,
}) => {
  const hoveredGeometry = useRef<Maybe<GeometryRef>>(null);
  const clickedGeometry = useRef<Maybe<GeometryRef>>(null);
  const { isLayerVisible } = useContext(LayerContext);

  const allInteractiveLayerIds = getInteractiveLayerIds(
    config.maps,
    isLayerVisible
  );

  useEffect(() => {
    if (!mapLoaded) {
      return;
    }

    if (clickedGeometry.current) {
      helpers.emit({
        type: "removeFeatureState",
        data: {
          field: "click",
          ...clickedGeometry.current,
        },
      });
    }

    if (config.value?.customMapId && config.value.geometryId) {
      const geometryFeature = helpers.featuresByFilter({
        layer: `${config.value.customMapId}-fill-color-polygons`,
        filter: ["==", "id", config.value.geometryId],
      })[0];

      if (geometryFeature) {
        helpers.emit({
          type: "setFeatureState",
          data: {
            id: geometryFeature.id,
            field: "click",
            source: geometryFeature.source,
            sourceLayer: geometryFeature.sourceLayer,
          },
        });

        clickedGeometry.current = geometryFeature;
      }
    }
  }, [config.value?.customMapId, config.value?.geometryId, mapLoaded]);

  return useMemo<LayerHook>(() => {
    return {
      interactiveLayerIds: allInteractiveLayerIds,
      zIndex: 2,
      render: () => {
        return config.maps.map(map => (
          <CustomMapLayer
            id={map.id}
            tileset={map.tileset}
            key={map.id}
            labelsVisibleOnMap={map.labelsVisibleOnMap}
          />
        ));
      },
      canHandleHover: () => !!config.interactive?.hover,
      onHover: ({ event }) => {
        if (hoveredGeometry.current) {
          helpers.emit({
            type: "removeFeatureState",
            data: { field: "hover", ...hoveredGeometry.current },
          });
          hoveredGeometry.current = null;
        }

        const feature = selectFeature({
          event,
          allInteractiveLayerIds,
          errorOnOverlap: true,
        });

        if (feature) {
          helpers.emit({
            type: "setFeatureState",
            data: {
              id: feature.id,
              field: "hover",
              source: feature.source,
              sourceLayer: feature.sourceLayer,
            },
          });

          helpers.setCursor("pointer");
          // This should always be present, but in case it's not,
          // let's not display an empty tooltip
          if (feature.properties.label) {
            helpers.emit({
              type: "setTooltip",
              data: {
                ...event.offsetCenter,
                text: feature.properties.label,
              },
            });
          }
          hoveredGeometry.current = {
            id: feature.id,
            source: feature.source,
            sourceLayer: feature.sourceLayer,
          };
        }
      },
      onFocusLost: () => {
        if (hoveredGeometry.current) {
          helpers.emit({
            type: "removeFeatureState",
            data: { field: "hover", ...hoveredGeometry.current },
          });
          hoveredGeometry.current = null;
        }
      },
      canHandleClick: event =>
        !!config.interactive?.click && !!event.features?.length,
      onClick: ({ event }) => {
        const feature = selectFeature({
          event,
          allInteractiveLayerIds,
          errorOnOverlap: false,
        });

        if (!feature) {
          return false;
        }

        if (
          clickedGeometry.current &&
          clickedGeometry.current.id !== feature.id
        ) {
          helpers.emit({
            type: "removeFeatureState",
            data: {
              field: "click",
              ...clickedGeometry.current,
            },
          });
        }

        clickedGeometry.current = {
          id: feature.id,
          source: feature.source,
          sourceLayer: feature.sourceLayer,
        };
        helpers.emit({
          type: "setFeatureState",
          data: {
            id: feature.id,
            field: "click",
            source: feature.source,
            sourceLayer: feature.sourceLayer,
          },
        });

        config.onClick?.({
          customMapId: (feature.source as string).replace(
            CUSTOM_MAPS_SOURCE_ID_PREFIX,
            ""
          ),
          geometryId: feature.id,
        });
        return true;
      },
    };
  }, [config]);
};

export default customMapsHook;
