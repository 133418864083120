import React from "react";

import Banner from "./Banner";

import { BANNER_TYPES } from "./__styles__/Banner";
import { Disclaimer } from "./__styles__/Disclaimer";

export default ({ message, testId }: { message: string; testId?: string }) => {
  return (
    <Banner
      testId={testId}
      type={BANNER_TYPES.NEUTRAL}
      style={{ marginTop: "16px" }}
    >
      <Disclaimer markdown={message} />
    </Banner>
  );
};
