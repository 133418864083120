import React from "react";
import { spacing } from "../../stitches.config";
import { StyledSections, StyledSection } from "../Common/__styles__/Layout";

export const Sections: React.FC<{
  children: React.ReactNode;
  gap?: typeof spacing[keyof typeof spacing];
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  onSubmit?: () => void;
}> = ({ children, gap = spacing.xl, as = "div", onSubmit }) => {
  return (
    <StyledSections
      style={{ display: "flex", flexDirection: "column", gap: gap.value }}
      as={as}
      onSubmit={onSubmit}
    >
      {children}
    </StyledSections>
  );
};

export const Section: React.FC<{
  children: React.ReactNode;
  gap?: typeof spacing[keyof typeof spacing];
  flexDirection?: "column" | "row";
}> = ({ children, gap = spacing.m, flexDirection = "column" }) => {
  return (
    <StyledSection style={{ display: "flex", flexDirection, gap: gap.value }}>
      {children}
    </StyledSection>
  );
};
