import React, {
  ComponentProps,
  Fragment,
  InputHTMLAttributes,
  ReactNode,
} from "react";
import { isNil } from "lodash";
import { ChangeHandler } from "react-hook-form";

import Wrapper, { WrapperProps } from "./Wrapper";
import { Input, InputWrapper, Symbol } from "./__styles__/TextInput";

// we override some of these props with our own definitions
// for instance, size is normally an integer for <input>
// element but we're using it here as an enum for the style
type StrippedDownInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "size" | "value" | "onChange" | "css"
>;

export const SymbolComponent = ({
  value,
  postfix,
  size,
}: {
  value?: Maybe<string>;
  postfix?: true;
  size?: ComponentProps<typeof Symbol>["size"];
}) => {
  if (isNil(value)) return null;

  return (
    <Symbol postfix={postfix} size={size}>
      {value}
    </Symbol>
  );
};

// For use with deprecated useForm forms
export type Props = StrippedDownInputProps & {
  name: string;
  type?: string;
  disabled?: boolean;
  size?: "smaller" | "small" | "medium" | "large";
  error?: Maybe<string>;
  hasErrorWithoutText?: boolean;
  onFocus?: () => void;
  value?: Maybe<string | number>;
  tooltip?: ReactNode;
  postfix?: string;
  prefix?: string;
  children?: WrapperProps["addon"];
} & Pick<
    WrapperProps,
    | "labelSize"
    | "compactLabel"
    | "labelTabIndex"
    | "label"
    | "required"
    | "description"
    | "helperText"
  >;

const TextInput = ({
  name,
  type = "text",
  value = null,
  onChange,
  label = null,
  compactLabel = false,
  disabled = false,
  size = "medium",
  helperText,
  error,
  hasErrorWithoutText,
  labelTabIndex,
  children,
  required,
  description,
  prefix,
  postfix,
  labelSize,
  tooltip,
  ...props
}: Props & {
  onChange?: (value: string) => void;
}) => {
  const InputWrapperComponent = !!prefix || !!postfix ? InputWrapper : Fragment;

  return (
    <Wrapper
      label={label}
      name={name}
      error={error}
      addon={children}
      required={required}
      description={description}
      labelTabIndex={labelTabIndex}
      compactLabel={compactLabel}
      labelSize={labelSize}
      tooltip={tooltip}
      helperText={helperText}
    >
      <InputWrapperComponent>
        <SymbolComponent value={prefix} />
        <Input
          type={type}
          name={name}
          value={value ?? ""}
          onChange={evt => onChange?.(evt.target.value)}
          disabled={disabled}
          id={name}
          error={!!error || hasErrorWithoutText}
          size={size}
          prefixed={!!prefix}
          postfixed={!!postfix}
          // prevents the input from increasing / decreasing
          // the value when the user scrolls *inside* the input
          onWheel={event => event.currentTarget.blur()}
          {...props}
        />
        <SymbolComponent value={postfix} postfix={true} size={size} />
      </InputWrapperComponent>
    </Wrapper>
  );
};

// For use with react-hook-form forms
export const ReactHookFormTextInput = React.forwardRef(
  (
    {
      name,
      type = "text",
      onChange,
      label = null,
      compactLabel = false,
      disabled = false,
      size = "medium",
      error,
      hasErrorWithoutText,
      labelTabIndex,
      children,
      required,
      description,
      expandWithError = true,
      tooltip,
      ...props
    }: Omit<Props, "value"> & {
      onChange?: ChangeHandler;
      expandWithError?: boolean;
    },
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <Wrapper
        label={label}
        compactLabel={compactLabel}
        name={name}
        error={error}
        labelTabIndex={labelTabIndex}
        addon={children}
        required={required}
        description={description}
        expandWithError={expandWithError}
        tooltip={tooltip}
      >
        <Input
          type={type}
          name={name}
          onChange={onChange}
          disabled={disabled}
          id={name}
          error={!!error || hasErrorWithoutText}
          size={size}
          ref={ref}
          {...props}
        />
      </Wrapper>
    );
  }
);

export default TextInput;
