import React, { ReactNode } from "react";
import {
  components,
  MultiValue,
  OnChangeValue,
  SingleValue,
} from "react-select";
import {
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import type {
  DropdownIndicatorProps,
  ClearIndicatorProps,
  Props,
  GroupBase,
  OptionProps,
  Theme,
} from "react-select";
import cx from "classnames";
import { CSS, VariantProps } from "@stitches/react";

import Wrapper from "./Wrapper";
import { StyledReactSelect } from "./__styles__/Select";
import { flatMap } from "lodash";
import { isNotNil } from "common/utils/tools";
import { Icon } from "../Common/Icons/LucideIcons";
import { colors } from "../../stitches.config";
import { TooltipWithText } from "../Common/Tooltip";

const { Option, DropdownIndicator, ClearIndicator } = components;
export { Option };
export type { GroupBase, OptionProps, Theme };

interface SelectOption<T> {
  value: Maybe<T>;
  label: string;
}

type SelectProps<T, Option extends SelectOption<T>, IsMulti extends boolean> = {
  value?: IsMulti extends true ? Maybe<T[]> : Maybe<T>;
  name: string;
  label?: string;
  onChange?: IsMulti extends true
    ? (value: Maybe<T[]>) => void
    : (value: Maybe<T>) => void;
  dropdownIndicatorColor?: keyof typeof colors;
  options: Array<Option> | Array<{ label: string; options: Array<Option> }>;
  disabled?: boolean;
  helperText?: Maybe<string>;
  error?: string;
  labelTabIndex?: number;
  children?: ReactNode;
  required?: boolean;
  description?: Maybe<string>;
  isClearable?: Maybe<boolean>;
  hideSelectedOptions?: Maybe<boolean>;
  size?: "smaller" | "small" | "medium" | "large";
  className?: string;
  hasErrorWithoutText?: boolean;
  tooltip?: ReactNode;
  css?: CSS;
} & Omit<
  Props<Option, IsMulti, GroupBase<Option>>,
  "value" | "onChange" | "options"
>;

// This function is a wrapper to provide type annotations and code completion
// suggestions to the styled React Select component.
// This is because the `styled` function does not provide a way to pass type
// generics to the component it wraps.
const ReactSelect = <Option, IsMulti extends boolean>(
  props: Props<Option, IsMulti> &
    VariantProps<typeof StyledReactSelect> & { css?: CSS }
) => {
  // We can cast to `any` here because `props` should already
  // be a more specific type than what `BaseStyledReactSelect` expects.
  return <StyledReactSelect {...(props as any)} />;
};

const isMulti = <T,>(
  value: Maybe<MultiValue<T>> | Maybe<SingleValue<T>>
): value is MultiValue<T> => {
  return Array.isArray(value);
};

const isGrouped = <T,>(
  value:
    | Array<SelectOption<T>>
    | Array<{ label: string; options: Array<SelectOption<T>> }>
): value is Array<{ label: string; options: Array<SelectOption<T>> }> => {
  return value.some(v => {
    return "options" in v && Array.isArray(v.options);
  });
};

// these weird type generics all come from here:
// https://react-select.com/typescript
const Select = <
  T,
  Option extends SelectOption<T>,
  IsMulti extends boolean = false
>({
  value,
  name,
  label,
  onChange,
  disabled = false,
  helperText,
  error,
  labelTabIndex,
  children,
  components,
  dropdownIndicatorColor = "contentSecondary",
  required,
  description,
  isClearable,
  hideSelectedOptions,
  className,
  size,
  hasErrorWithoutText,
  tooltip,
  ...reactSelectProps
}: SelectProps<T, Option, IsMulti>) => {
  const handleChange = (selected: Maybe<OnChangeValue<Option, IsMulti>>) => {
    if (isMulti(selected)) {
      (onChange as (value: Maybe<T>[]) => void)(
        selected.map(option => option.value)
      );
    } else {
      (onChange as (value: Maybe<T>) => void)(selected?.value ?? null);
    }
  };
  const CustomDropdownIndicator = (
    props: DropdownIndicatorProps<Option, IsMulti>
  ) => {
    return (
      <DropdownIndicator {...props}>
        <Icon
          iconName="chevron-down"
          color={dropdownIndicatorColor}
          size={16}
        />
      </DropdownIndicator>
    );
  };

  const CustomClearIndicator = (
    props: ClearIndicatorProps<Option, IsMulti>
  ) => {
    return (
      <ClearIndicator {...props}>
        <TooltipWithText
          hoverText={<Icon iconName="x" color="contentDisabled" size={16} />}
          tooltipText="Clear selection"
        ></TooltipWithText>
      </ClearIndicator>
    );
  };

  const computeValue = () => {
    const options = isGrouped(reactSelectProps.options)
      ? flatMap(reactSelectProps.options, group => group.options)
      : reactSelectProps.options;

    if (isMulti(value)) {
      return value
        .map(val => options.find(opt => opt.value === val))
        .filter(isNotNil);
    } else {
      return options.filter(opt => opt.value === value);
    }
  };

  return (
    <Wrapper
      label={label}
      required={required}
      description={description}
      name={name}
      helperText={helperText}
      error={error}
      addon={children}
      labelTabIndex={labelTabIndex}
      tooltip={tooltip}
    >
      <ReactSelect
        value={computeValue()}
        onChange={handleChange}
        isDisabled={disabled}
        classNamePrefix={cx(name, size, className, {
          error: !!error || hasErrorWithoutText,
          select: true,
        })}
        inputId={name}
        isClearable={isClearable}
        hideSelectedOptions={hideSelectedOptions}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          ClearIndicator: CustomClearIndicator,
          ...components,
        }}
        size={size}
        {...reactSelectProps}
      />
    </Wrapper>
  );
};

export default Select;

type ReactHookFormSelectProps<
  T,
  IsMulti extends boolean,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> &
  SelectProps<T, SelectOption<T>, IsMulti>;

export function ReactHookFormSelect<
  T extends FieldPathValue<TFieldValues, TName>,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>({
  control,
  name,
  rules,
  onChange,
  ...reactSelectProps
}: ReactHookFormSelectProps<T, IsMulti, TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <Select<T, SelectOption<T>, IsMulti>
            name={name}
            value={field.value}
            onChange={(value: any) => {
              field.onChange(value);
              onChange?.(value);
            }}
            {...reactSelectProps}
          />
        );
      }}
    />
  );
}
