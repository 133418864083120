import React from "react";
import { MapEvent, Marker } from "react-map-gl";
import { Icon as LucideIcon } from "../../Common/Icons/LucideIcons";
import { Body } from "../../Common/Typography";
import {
  CLICK_OBJECT_MENU_WIDTH,
  HeaderBar,
  MarkerBody,
  MenuCategory,
  MenuItems,
} from "./__styles__/clickObjectMenu";
import { useHotkeys } from "react-hotkeys-hook";
import { EventFeature } from "../LayeredMap";

export type MenuCategory = {
  header: string;
  objects: ObjectDisplayProps[];
};

const CategoryDisplay = ({ header, objects }: MenuCategory) => (
  <MenuCategory key={header}>
    <Body type="emphasis" size="small" color="contentPrimaryDark">
      {header.toUpperCase()}
    </Body>
    {objects.map(ObjectDisplay)}
  </MenuCategory>
);

export type ObjectDisplayProps = {
  label: string;
  id: string;
  onClick: () => void;
};

const ObjectDisplay = ({ id, label, onClick }: ObjectDisplayProps) => (
  <Body
    type="emphasis"
    size="small"
    color="contentInfoDark"
    style={{ cursor: "pointer" }}
    key={id}
  >
    <span onClick={onClick}>{label}</span>
  </Body>
);

export type ClickMenuDisplayConfig = {
  latitude: number;
  longitude: number;
  displayOnLeft: boolean;
  topPixelOffset: number | null;
};

type ClickObjectMenuProps = {
  menuDisplayConfig: ClickMenuDisplayConfig | null;
  time: number;
  menuCategories: MenuCategory[];
  closeMenu: (features?: EventFeature[], event?: MapEvent) => void;
};

const ClickObjectMenuLayer = ({
  menuDisplayConfig,
  time,
  menuCategories,
  closeMenu,
}: ClickObjectMenuProps) => {
  if (!menuDisplayConfig) return null;
  const { latitude, longitude, displayOnLeft, topPixelOffset } =
    menuDisplayConfig;

  useHotkeys("esc", e => {
    e.preventDefault();
    closeMenu();
  });

  return (
    <Marker
      key={`click-object-menu-${time}`}
      latitude={latitude}
      longitude={longitude}
      offsetLeft={displayOnLeft ? -CLICK_OBJECT_MENU_WIDTH - 10 : 10}
      offsetTop={topPixelOffset ? topPixelOffset - 10 : -10}
    >
      <MarkerBody>
        <HeaderBar>
          <Body type="emphasis" size="small" color="contentPrimaryDark">
            Objects
          </Body>
          <LucideIcon
            iconName="x"
            color="contentSecondaryDark"
            size={12}
            onClick={() => closeMenu()}
            data-testid="close-icon"
            style={{ cursor: "pointer" }}
          />
        </HeaderBar>
        <MenuItems>{menuCategories.map(CategoryDisplay)}</MenuItems>
      </MarkerBody>
    </Marker>
  );
};

export default ClickObjectMenuLayer;
