import React, { useState, ComponentProps } from "react";

import ListItem from "./ListItem";
import { EmptyState } from "../../Common/EmptyState";
import ImprovementListItem from "./ImprovementListItem";
import {
  Container,
  EmptyContainer,
  ExclusionGroupTitle,
  MoreButton,
  Table,
} from "./__styles__/List";
import { pluralize } from "common/utils/strings";
import { partition } from "lodash";
import { ImprovementExclusionReason } from "../../../generated/graphql";
import { PartialImprovement } from "./utils";

type ListProps = Pick<
  ComponentProps<typeof ListItem>,
  "address" | "onUpdate"
> & {
  improvements: Array<PartialImprovement>;
};

const List = ({
  address,
  improvements: allImprovements,
  onUpdate,
}: ListProps) => {
  const [showAll, setShowAll] = useState(false);

  const [excludedImprovements, includedImprovements] = partition(
    allImprovements,
    "exclusion.excluded"
  );

  const hiddenCount = excludedImprovements.length;

  if (!allImprovements.length) {
    return (
      <EmptyContainer>
        <EmptyState compact title="There are no entries for this property" />
      </EmptyContainer>
    );
  }

  const [manualExcludedImprovements, autoExcludedImprovements] = partition(
    excludedImprovements,
    improvement =>
      improvement.exclusion!.reason === ImprovementExclusionReason.MANUAL
  );

  return (
    <Container>
      <Table data-testid="improvements-table" noExclusions={!hiddenCount}>
        {includedImprovements
          .sort((a, b) => a.date - b.date)
          .map(item => (
            <ImprovementListItem
              address={address}
              item={item}
              onUpdate={onUpdate}
              key={item.id}
            />
          ))}
        {showAll && !!manualExcludedImprovements.length && (
          <>
            <ExclusionGroupTitle>Manual</ExclusionGroupTitle>
            {manualExcludedImprovements
              .sort((a, b) => a.date - b.date)
              .map(item => (
                <ImprovementListItem
                  address={address}
                  item={item}
                  onUpdate={onUpdate}
                  key={item.id}
                />
              ))}
          </>
        )}
        {showAll && !!autoExcludedImprovements.length && (
          <>
            <ExclusionGroupTitle>Tracking period</ExclusionGroupTitle>
            {autoExcludedImprovements
              .sort((a, b) => a.date - b.date)
              .map(item => (
                <ImprovementListItem
                  address={address}
                  item={item}
                  onUpdate={onUpdate}
                  key={item.id}
                />
              ))}
          </>
        )}
      </Table>
      {!!hiddenCount && (
        <MoreButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Hide" : "Show"} {hiddenCount} excluded{" "}
          {pluralize({
            count: hiddenCount,
            options: { singular: "record", plural: "records" },
          })}
        </MoreButton>
      )}
    </Container>
  );
};

export default List;
